import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styles from "./GetLiveClassDetails.module.css";
import { getLiveClassDetails } from "api";
export default function GetLiveClassDetails({ onClick, liveClasssId }) {
  const [datas, setDatas] = useState({});
  const [techSupportError, setTechSupportError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    console.log("claling");
    onClick();
  };
  useEffect(() => {
    let userId = localStorage.getItem("user");
    setIsLoading(true);
    fetchingDatas(userId, liveClasssId);
  }, [liveClasssId]);
  const fetchingDatas = async (userId, liveClasssId) => {
    await getLiveClassDetails(userId, liveClasssId)
      .then((res) => res.json())
      .then((res) => {
        setDatas(res?.live_class_details || {});
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setDatas({});
      });
  };
  return isLoading ? (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <h1>Loading...</h1>
      </div>
    </div>
  ) : (
    <div>
      <>
        <div>
          <div className={styles.modalBackground}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Live Class Details
            </div>
            {
              <div className={styles.modalContainer}>
                <div className={styles.titleCloseBtn}>
                  <button
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    X
                  </button>
                </div>

                <div className={styles.secondmainContainer}>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Teacher Details</h3>

                    {datas?.teacher?.first_name ? (
                      <table border="1" className={styles.studentsTable}>
                        <tr>
                          <th>Teacher Name</th>
                          <th>Email</th>
                          <th>User Name</th>
                          <th>Active</th>
                        </tr>
                        <tr>
                          <td>{datas?.teacher?.first_name}</td>
                          <td>
                            {
                              <a href={`mailto:${datas?.teacher?.email}`}>
                                {datas?.teacher?.email}
                              </a>
                            }
                          </td>
                          <td>{String(datas?.teacher?.user_name)}</td>
                          <td>
                            {datas?.teacher?.is_active === true
                              ? "Yes"
                              : datas?.teacher?.is_active === false
                              ? "No"
                              : "Not Defined"}
                          </td>
                        </tr>
                      </table>
                    ) : (
                      <span>
                        <b>Not Present</b>
                      </span>
                    )}
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Students</h3>
                    {datas?.students?.length > 0 ? (
                      <table border="1" className={styles.studentsTable}>
                        <tr>
                          <th>Name</th>
                          <th>User name</th>
                          <th>Country</th>
                        </tr>
                        {datas?.students?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {item?.first_name}&nbsp;{item?.last_name}
                              </td>
                              <td>{item?.user_name}</td>
                              <td>{String(item?.country)}</td>
                            </tr>
                          );
                        })}
                      </table>
                    ) : (
                      <span>
                        <b>Not Present</b>
                      </span>
                    )}
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Class Start Time</h3>
                    <span>{datas?.start_time ?? <b>Not Present</b>}</span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Class End Time</h3>
                    <span>{datas.end_time ?? <b>Not Present</b>}</span>
                  </div>

                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Status</h3>
                    <span>{datas.status ?? <b>Not Present</b>}</span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Support Required</h3>
                    <span>
                      {datas?.status !== undefined ? (
                        datas?.status === true ? (
                          "Yes"
                        ) : (
                          "No"
                        )
                      ) : (
                        <b>Not Present</b>
                      )}
                    </span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Recording Links</h3>
                    <span>{datas?.recording_links ?? <b>Not Present</b>}</span>
                  </div>
                </div>
                {/* </>
                );
              })} */}
              </div>
            }
          </div>
        </div>
      </>
    </div>
  );
}
