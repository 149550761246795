import styles from "../custom.module.css";
import SuiTypography from "components/SuiTypography";
import Table from "examples/Table";
import ViewMoreSidErrorLog from "./ViewMoreSidFromErrorLog/ViewMoreSidErrorLog";
import { useState } from "react";

function Modal({ child, modalData, data_teacher,currentDate,errorData,currentMessage,onClose,onClick }) {
  const closeModal = () => {
    child();
  };
 
  let info_obj = {};
  for (let i = 0; i < modalData.length; i++) {
    if (info_obj[modalData[i]] == undefined) {
      info_obj[modalData[i]] = {
        count: 1,
      };
    } else {
      info_obj[modalData[i]] = {
        count: info_obj[modalData[i]].count + 1,
      };
    }
  }
  
  for (let key in data_teacher) {
    for (let key_1 in info_obj) {
      if (key == key_1) {
        info_obj[key] = {
          ...info_obj[key],
          teacher: data_teacher[key].teacher,
          student: data_teacher[key].student,
        };
      }
    }
  }
  let data_keys = Object.keys(info_obj);
  data_keys.forEach((e, i) => {
    let arr = info_obj[e].student;
    info_obj[e].student = arr.join(",");
  });
// for(let item of errorData)
// {

// for(let keys in info_obj)
// {
//   if(keys==item.live_class_id)
// {
//   if(String(item.message)===String(currentMessage).trim())
//   {
//     if(Array.isArray(info_obj[keys]?.dates))
//   {
//     let temp=info_obj[keys]?.dates
//     temp.push(item.created_at)
//     info_obj[keys].dates=[...temp]
   
//   }
//   else{
//     let temp=[item.created_at]
//     info_obj[keys].dates=[...temp]
//   }
  
//   }
// }
  
// }
// }
// let monthRef=({
//   Jan: "01",
//   Feb: "02",
//   Mar: "03",
//   Apr: "04",
//   May: "05",
//   Jun: "06",
//   Jul: "07",
//   Aug: "08",
//   Sep: "09",
//   Oct: "10",
//   Nov: "11",
//   Dec: "12",
// })
// for(let keys in info_obj){
//   let dates=info_obj[keys]?.dates
// for(let i=0;)
// }
  const [currentLiveClassId,setCurrentLiveClassId]=useState("")
  const handleCloseChildModal=()=>{
    setCurrentLiveClassId('')
    onClose()
  }

  return <>{currentLiveClassId?<ViewMoreSidErrorLog liveClassId={currentLiveClassId} currentDate={currentDate} errorData={errorData} message={currentMessage} onClick={handleCloseChildModal} participantDetails={data_teacher[currentLiveClassId]}/>:
    <div className={styles.modalBackground} style={{display:`${currentLiveClassId?"none":'flex'}`,width:'100%'}}>
      <div className={styles.modalContainer}>
        <div className={styles.titleCloseBtn}>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            X
          </button>
        </div>
        <div className={styles.mapped}>
          <Table
            columns={[
              { name: "index", align: "center " },
              { name: "Teacher_Name", align: "left" },
              { name: "Student_Name", align: "left" },
              { name: "Live_Class_Id", align: "center" },
              { name: "count", align: "center" },
            ]}
            rows={data_keys.map((e, i) => {
              return {
                index: i + 1,
                Teacher_Name: info_obj[e].teacher,
                Student_Name: info_obj[e].student,
                Live_Class_Id: e,
                count: info_obj[e].count,
              };

            })}

           onClick={onClick}
            setCurrentLiveClassId={setCurrentLiveClassId}
            type={"function"}
          />
        </div>
      </div>
    </div>}
    </>
          
  
}

export default Modal;
