import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { useEffect, useState } from "react";
import styles from "./charts.module.css";
import SuiButton from "components/SuiButton";
import DailyChart from "./DailyChart";

function Charts({ dataTochart, changechart }) {
  const [weeklyData, setWeeklyData] = useState([]);
  const [showdaily, setshowDaily] = useState(false);
  useEffect(() => {
    let week_1 = 0;
    let week_2 = 0;
    let week_3 = 0;
    let week_4 = 0;
    let arr = [];
    let num_data = dataTochart.map((e) => Number(e.created_at.trim().split(" ")[0]));
    for (let j = 0; j < num_data.length; j++) {
      if (num_data[j] >= 1 && num_data[j] <= 7) {
        week_1++;
      }
      if (num_data[j] >= 8 && num_data[j] <= 14) {
        week_2++;
      }
      if (num_data[j] >= 15 && num_data[j] <= 21) {
        week_3++;
      }
      if (num_data[j] > 21) {
        week_4++;
      }
    }
    arr.push(week_1);
    arr.push(week_2);
    arr.push(week_3);
    arr.push(week_4);
    setWeeklyData(arr);
  }, []);
  const handleBack = () => {
    changechart();
  };
  const change = () => {
    setshowDaily(false);
  };
  return (
    <>
      {showdaily ? (
        <DailyChart dataTochart={dataTochart} change={change} changechart={changechart} />
      ) : (
        <DashboardLayout>
          <div className={styles.navchart}>
            <SuiButton onClick={() => setshowDaily(true)}>Show Daywise Data</SuiButton>
            <SuiButton onClick={handleBack}>Go Back</SuiButton>
          </div>
          <DefaultLineChart
            //title="Weekly data for month of september"
            chart={{
              labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
              datasets: [
                {
                  label: "Errors",
                  color: "info",
                  data: weeklyData,
                },
              ],
            }}
          />
        </DashboardLayout>
      )}
    </>
  );
}
export default Charts;
