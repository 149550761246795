import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import "../../../index.css"
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Completed",
  "Approved",
  "Cancelled"
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DaywiseStatusField({classStatus,onClick}) {
  const theme = useTheme();


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if(value[value.length-1]==="Status")
    {
      onClick([])
      return
    }
    let values=typeof value === 'string' ? value.split(',') : value
    values=values?.filter(item=>item!=="Status")
   onClick(values)
  };

  return (
    <div id="daywiseStatusField">
      <FormControl style={{maxWidth:'80px',minWidth:'80px'}}>
       
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={classStatus?.length>0?classStatus:["Status"]}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
            <MenuItem
              key={"Status"}
              value={'Status'}
              style={getStyles("Status", classStatus, theme)}
            >
              Status
            </MenuItem>
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, classStatus, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
