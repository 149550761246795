/* eslint-disable no-unused-vars */
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect } from "react";
import IncompleteClassRecordingDetails from "./IncompleteClassRecordingDetails";

function IncompleteClassRecording() {
  useEffect(() => {
    // completeRecordingParts();
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <IncompleteClassRecordingDetails />
      </DashboardLayout>
    </>
  );
}
export default IncompleteClassRecording;
