import React, { useEffect, useRef, useState } from "react";
import styles from "../custom2.module.css";
import styles2 from "../custom1.module.css";
import Dropzone from "react-dropzone";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import ImageReview from "./ImageReview";
import { getRaiseTicketData } from "api";
import { SendRaiseTicketDetails } from "api";
import SuccessfulMessageModal from "./SuccessfulMessageModal";
import ModalMessage from "./ModalMessage";
export default function RaiseTicketForm({ onClick, liveclassid }) {
  const [formData, setFormData] = useState({
    errorType: "",
    errorDesc: "",
    browser: "",
    os: "",
    device: "",
    fileType: "",
  });
  const [suggestion, setSuggestion] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const fileRef = useRef(null);
  const [startSuggestion, setStartSuggestion] = useState(true);
  const handleErrorChange = (e) => {
    setFormData({ ...formData, errorType: e.target.value });
    if (e.target.value && String(e.target.value).trim()) {
      setStartSuggestion(true);
      let temp = errorList.filter((item, i) => {
        let temp1 = item.toLowerCase();
        let temp2 = String(e?.target?.value).toLowerCase();
        return temp1.includes(temp2);
      });
      setSuggestion([...temp]);
    } else {
      setStartSuggestion(false);
      setSuggestion([]);
    }
  };
  const handleSelectValue = (value) => {
    setStartSuggestion(false);
    let obj = { ...formData, errorType: value };
    setFormData({ ...obj });
  };
  const handleSelectChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const [ticketRaiseStatus, setTicketRaiseStatus] = useState(false);

  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, errorDesc: e.target.value });
  };
  const handleClose = () => {
    onClick();
  };
  const handleSubmit = (e) => {
  
    let user_id = localStorage.getItem("user");
console.log(user_id)
    e.preventDefault();
    for (let key in formData) {
      if (key !== "errorDesc" && key !== "fileType") {
        if (!String(formData[key]).trim()) {
          alert("All Field are Mandatory.");
        return
        }
      }
    }

    if (totalFilesPath.length < 1) {
      alert("All Field are Mandatory.");
      return;
    }

    sendErrorDatails(user_id);
  };
  const sendErrorDatails = async (user_id) => {
   
    let forms = new FormData();

    forms.append("error_type", formData?.errorType);
    forms.append("browser", formData?.browser);
    forms.append("device", formData?.device);
    forms.append("operating_system", formData?.os);
    forms.append("error_description", formData?.errorDesc);
    forms.append("image", formData?.browser);
    forms.append("live_class_id", liveclassid);
    for (let items of totalFilesPath) forms.append("images[]", items);
    forms.append("status", "ticket_raised");
    

    try {
       await SendRaiseTicketDetails(forms,user_id).then((res)=>{
return res.json()
       }).then(res=>{
        setTicketRaiseStatus(true);
     
      setError(false)
       }).catch(e=>{

        setTicketRaiseStatus(false);
        setError(true)
       });
     
      
    } catch (e) {
      console.log(e);
      setError(true)
    }
  };
  useEffect(() => {
    setIsLoading(true)
    fetchData();
  }, []);
  const [result, setResult] = useState({});
  const fetchData = async () => {
    try {
      let user_id = localStorage.getItem("user");
      let  data  = await getRaiseTicketData(user_id).then(res=>res.json());
      setIsLoading(false)
      setResult({ ...data?.tech_support_details });
      setError(false)
      setErrorList([...data?.tech_support_details?.error_type]);
    } catch (e) {
      console.log(e);
setError(true)
setIsLoading(false)
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const [error,setError]=useState(false)

  const [totalFilesPath, setTotalFilesPath] = useState([]);
  let [totalFiles, setTotalfiles] = useState([]);
  const dropzoneRef = useRef();
  const [currentPreviewImage, setCurrentReviewImage] = useState("");

  const handleFileDrop = (e) => {
    let totalFiles = [];
    for (let item of e) {
      totalFiles.push(item.name);
    }
    setTotalFilesPath([...e]);
    setTotalfiles(totalFiles);
  };
  const handleImagePreview = (src) => {
    setCurrentReviewImage(src);
  };
  const handleCloseImages = (index) => {
    let temp = totalFilesPath.filter((_, i) => i !== index);
    setTotalFilesPath([...temp]);
  };
  const handleImagePreviewClose = () => {
    setCurrentReviewImage("");
  };
  return (
    <div id={styles.parentId}>
    
      <div
        className={styles.modalBackground}
        style={{ width: "calc(100% - 3rem)", margin: "auto", height: "600px",opacity:'1' }}
      >
        
        {currentPreviewImage && (
          <ImageReview src={currentPreviewImage} onClick={handleImagePreviewClose} />
        )}
        {ticketRaiseStatus ? (
          <ModalMessage onClick={onClick} message={"Ticket Has Succesffully Created."} />
        ) : (
          <div className={styles.modalContainer} style={{ width: "100%", height: "600px" }}>
            <div className={styles.titleCloseBtn}>
              <button
                onClick={() => {
                  handleClose();
                }}
              >
                X
              </button>
            </div>
           {error&& <div className={styles.warningMsg}>Something went wrong please try again...</div>}

           {isLoading?<h1>Loading...</h1>: <form onSubmit={handleSubmit}>
          
              <div className={styles2.secondmainContainer}>
                <div className={styles2.secondmainContainerflexbox}>
                  <h3>Error Type:</h3>
                  <div className={styles.suggestionContainer}>
                    <TextField
                      id="outlined-textarea"
                      placeholder="Error Type"
                      onChange={handleErrorChange}
                      multiline
                      style={{ maxWidth: "500px", fontSize: 20, width: "290px" }}
                      value={formData?.errorType}
                    />
                    {startSuggestion && suggestion?.length > 0 && (
                      <div className={styles.errorList}>
                        {suggestion.map((item,i) => (
                          <div      key={i} onClick={() => handleSelectValue(item)}>{item}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles2.secondmainContainerflexbox}>
                  <h3>Error Description (optional):</h3>
                  <div>
                    <TextField
                      id="outlined-textarea"
                      placeholder="Error Description"
                      multiline
                      style={{ maxWidth: "500px", fontSize: 20, width: "290px" }}
                      value={formData.errorDesc}
                      onChange={handleDescriptionChange}
                      minRows={4}
                    />
                  </div>
                </div>

                <div className={styles2.secondmainContainerflexbox}>
                  <h3>Browser</h3>
                  <div style={{ minWidth: "50px", width: "auto" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.browser || " "}
                      onChange={(e) => handleSelectChange("browser", e.target.value)}
                      autoWidth
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <MenuItem value={" "} style={{ fontSize: 20 }}>
                        Select Browser
                      </MenuItem>
                      {result?.browser?.map((item, i) => (
                        <MenuItem value={item} key={i}>{item}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className={styles2.secondmainContainerflexbox}>
                  <h3>Operating Sytem</h3>
                  <div style={{ minWidth: "50px", width: "auto" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.os || " "}
                      onChange={(e) => handleSelectChange("os", e.target.value)}
                      autoWidth
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <MenuItem value={" "} style={{ fontSize: 20 }}>
                        select OS
                      </MenuItem>
                      {result?.operating_system?.map((item, i) => (
                        <MenuItem value={item} key={i}>{item}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={styles2.secondmainContainerflexbox}>
                  <h3>Device</h3>
                  <div style={{ minWidth: "50px", width: "auto" }}>
                    <Select
                      id="demo-simple-select"
                      value={formData.device || " "}
                      onChange={(e) => handleSelectChange("device", e.target.value)}
                      autoWidth
                      style={{ color: "black", fontSize: "20px" }}
                    >
                      <MenuItem value={" "} style={{ fontSize: 20 }}>
                        Select Device
                      </MenuItem>

                      {result?.device?.map((item, i) => (
                        <MenuItem value={item} key={i}>{item}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  className={styles2.secondmainContainerflexbox}
                  style={{ alignItems: "flex-start" }}
                >
                  <h3>Add Attachment:</h3>
                  <div>
                    <div style={{ cursor: "pointer" }} className={styles.dropzonecontainer}>
                      <Dropzone
                        ref={dropzoneRef}
                        onDrop={handleFileDrop}
                        accept={{
                          "image/*": [".svg", ".jpeg", ".png", ".jpg"],
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} ref={dropzoneRef} />
                            <p>Choose or Drag Files</p>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {totalFilesPath?.length > 0 && (
                      <div className={styles.imageListContainer}>
                        {totalFilesPath?.map((item, i) => (
                          <div id={styles.imageParentContainer} key={i}>
                            <div className={styles.closeImage} onClick={() => handleCloseImages(i)}>
                              X
                            </div>
                            <div className={styles.imagePreview}>
                              <img
                                src={URL.createObjectURL(item)}
                                onClick={() => handleImagePreview(URL.createObjectURL(item))}
                              />
                            </div>
                            <div>{item.name}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <input type="submit" className={styles.submitBtn} />
              </div>
            </form>}
            {/* </>
                );
              })} */}
          </div>
        )}
      </div>
    </div>
  );
}
