/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import { useEffect, useState } from "react";
import { DashboardClassCount } from "api";
import GetLiveClassDetails from "./GetLiveClassDetails/GetLiveClassDetails";

function Dashboard() {
  const [showLoading,setShowLoading]=useState(true)
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
const [datas,setDatas]=useState({})
const [liveClassId,setLiveClassId]=useState("")
const handleChangeLiveClassId=(value)=>{
  setLiveClassId(value)
  setShowLiveClassDetails(true)
}
useEffect(()=>{
  setShowLoading(true)
fetchingDatas()
},[])
const fetchingDatas=async()=>{
  let userId=localStorage.getItem('user')
  await DashboardClassCount(userId).then(res=>res.json()).then(res=>{
    setDatas({...res})
    setShowLoading(false)
  }).catch(e=>{
    setShowLoading(false)
    console.log(e)
  })
}
const handleCloseLiveClassDetails=()=>{
  setShowLiveClassDetails(false)
  setLiveClassId("")
}
const [showLiveClassDetails,setShowLiveClassDetails]=useState(false)
  return (
    <DashboardLayout>
      {<DashboardNavbar onClick={handleChangeLiveClassId}/>}
     {!showLoading? (!showLiveClassDetails?<SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Classes" }}
                count={datas?.total_class_count||0}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "", component: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Active Classes" }}
                count={datas?.total_active_class_count||0}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "", component: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Raised Tickets" }}
                count={datas?.total_raised_ticket_count||0}
                percentage={{ color: "success", text: "" }}
                icon={{ color: "", component: "emoji_events" }}
              />
            </Grid>
           
          </Grid>
        </SuiBox>
      
   
    
      </SuiBox>:<GetLiveClassDetails liveClasssId={liveClassId} onClick={handleCloseLiveClassDetails}/>):<h1>Loading...</h1>
}
    </DashboardLayout>
  );
}

export default Dashboard;
