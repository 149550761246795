import Axios from "axios";
import { API_SERVER } from "../config/constant";
// const raiseTicketUrl = "https://testserver.begalileo.com/live_class_admins/";
// const mediaRegionUrl = "";
// export const baseUrl="https://testserver.begalileo.com/"
// export const baseUrl = "https://begalileo.com/";
export const baseUrl = "https://www.begalileo.com/";
// export const baseUrl = "https://staging.begalileo.com/";
//  export const baseUrl="http://192.168.201.33:3000/"
const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
});

axios.interceptors.request.use(
  (config) => {
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;

export const getRaiseTicketData = (user_id = 18588) => {
  return fetch(`${baseUrl}live_class_admins/get_tech_support_details?user_id=${user_id}`);
};

export const SendRaiseTicketDetails = (formdata, userId) => {
  var myHeaders = new Headers();
  myHeaders.append("Cookie", "country=India");
  console.log("erro");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  return fetch(
    `${baseUrl}live_class_admins/sent_tech_support_details?user_id=${userId}`,
    requestOptions
  );
};

export const sendMediaRegionDetails = (liveclassid, userId, value) => {
  return fetch(
    `${baseUrl}live_class_admins/store_live_class_media_region?user_id=${userId}&live_class_id=${liveclassid}&media_region=${value}`
  );
};
export const enableLiveClass = (userId, liveClassId) => {
  return fetch(
    `${baseUrl}live_class_admins/enable_join_class?user_id=${userId}&live_class_id=${liveClassId}`
  );
};
export const disableLiveClass = (userId, liveClassId) => {
  return fetch(
    `${baseUrl}live_class_admins/disable_join_class?user_id=${userId}&live_class_id=${liveClassId}`
  );
};

export const JoinLiveClass = (userId, liveClassId) => {
  return fetch(
    `${baseUrl}live_class_admins/join_class?user_id=${userId}&live_class_id=${liveClassId}`
  );
};
export const closedTechSupport = (userId, liveClassId) => {
  return fetch(
    `${baseUrl}live_class_admins/close_support?user_id=${userId}&live_class_id=${liveClassId}`
  );
};
export const closeErrorLog = (userId, codes) => {
  return fetch(
    `${baseUrl}live_class_admins/close_all_error_logs?user_id=${userId}&error_log_code=${codes}`
  );
};

export const submitErrorLog = async (
  user_id,
  live_class_id,
  message,
  error_code,
  network_quality
) => {
  console.log("calling");
  return fetch(
    `${baseUrl}app_students/create_error_log?user_id=${user_id}&error_log_code=${error_code}&live_class_id=${live_class_id}&message=${message}&error_code=${error_code}&network_quality=${network_quality}`
  )
    .then((res) => res.json())
    .then((res) => console.log(res));
};

export const DashboardClassCount = (userId) => {
  let todayDate = new Date();
  todayDate = todayDate.toISOString().split("T")[0];
  todayDate = todayDate.replaceAll("-", "/");

  return fetch(
    `${baseUrl}live_class_admins/dashbord_class_count?user_id=${userId}&start_date=${todayDate}`
  );
};

export const getLiveClassDetails = (userId, liveClassId) => {
  return fetch(
    `${baseUrl}live_class_admins/get_live_class_details?user_id=${userId}&live_class_id=${liveClassId}`
  );
};
export const closedRaiseTicket = (userId, errorId) => {
  return fetch(
    `${baseUrl}live_class_admins/close_raised_ticket?user_id=${userId}&raised_ticket_id=${errorId}`
  );
};
export const getAllRecordingUploadId = async (
  live_class_id
) =>
  Axios.get(baseUrl + "app_students/get_live_class_recording_details", {
    params: {
      live_class_id,
    },
  });

  
export const recordingUpdateToServer = async (
  live_class_id,
 user_id
) =>
Axios.get(`${baseUrl}live_class_admins/complete_recording`,{
  params:{
    live_class_id,
    user_id
  }
})
  // Axios.get(baseUrl + "app_students/check_recording_api", {
  //   params: {
  //     live_class_id,
  //     recording_links,
  //     recording_id,
  //   },
  // });