/* eslint-disable no-debugger */
import styles from "../custom.module.css";
import { useEffect } from "react";


function ModalforRecord({ childforRecord, recordingData }) {
  console.log({ recordingData });

  const closeModal = () => {
    childforRecord();
  };

  useEffect(() => {
    // ffprobe(
    //   "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    //   { path: ffprobeStatic.path },
    //   function (err, info) {
    //     if (err) console.error("Errr", err);
    //     else console.log(info);
    //   }
    // );

    var vid = document.getElementById("myVideoPlayer");
    vid.addEventListener("loadedmetadata", function () {
      if (vid.duration == Infinity) {
        vid.currentTime = 24 * 60 * 60;
        vid.ontimeupdate = function () {
          this.ontimeupdate = () => {
            return;
          };
          vid.currentTime = 0;
          return;
        };
      }
    });
    console.log("Video Duration", vid.duration);
  });

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.titleCloseBtn}>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            X
          </button>
        </div>
        {recordingData ? (
          <div className={styles.modal_mapped_recording}>
            <video
              id="myVideoPlayer"
              src={recordingData}
              width={700}
              height={500}
              controls
              preload="metadata"
            ></video>
          </div>
        ) : (
          <div>No Recording Present</div>
        )}
      </div>
    </div>
  );
}

export default ModalforRecord;
