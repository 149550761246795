/* eslint-disable no-unused-vars */
import Card from "@mui/material/Card";
import SuiButton from "components/SuiButton";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState, useRef } from "react";
import AWS from "aws-sdk";
var bucketName = "begalileo-liveclass-recordings";
AWS.config.region = "ap-south-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: "ap-south-1:b06bcd03-3bde-4dd0-a552-7e68221a75d2",
});
var s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: bucketName },
});
let userID = 18588;

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
import AdminApi from "api/admin";
//import Table from "examples/Table";
import styless from "layouts/tables/styles";
import styles from "layouts/daywise/custom.module.css";
import { baseUrl } from "api";
import { getAllRecordingUploadId } from "api";
import axios from "axios";
import { recordingUpdateToServer } from "api";
//import { Table } from "@mui/material";
export const recordingFailedError = (body) => {
  let baseURL=""
  let url = baseURL + "app_students/create_recording_error_logs";
  return axios.post(url, body);
};
function IncompleteClassRecordingDetails() {
  const vref = useRef(null);
  const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate()-1));
  const [currentDate, setCurrentDate] = useState("");
  const [recordingList, setRecordingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [liveClassid, setLiveClassID] = useState([]);
  const [checkOnChange, setOnChange] = useState(false);
  const classes = styless();
  const [showLoading,setShowLoading]=useState(true)
  const sendUploadingError = async (e,live_class_id,recordingID, partsNumber = "") => {
    try {
      console.log("partNumber", partsNumber);
      let body = {
        live_class_id: live_class_id,
        error_info: e,
        recording_id: recordingID,
        part_no: partsNumber?.PartNumber ?? "",
      };
      let formData = new FormData();
      formData.append("error_info", e);
      formData.append("recording_id", recordingID);
      formData.append("part_no", partsNumber?.PartNumber ?? "");

      let y = await recordingFailedError(formData);
      console.log("partNumber", partsNumber?.PartNumber);
      console.log(y);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setShowLoading(true)
    console.log("Recordings");
    getIncompleteRecording();
  }, [currentDate]);

  useEffect(() => {
    vref.current.scrollIntoView({ behavior: "smooth" });
    console.log("Current Posts", currentPosts);
  }, [currentPage]);

  useEffect(() => {
    var x = document.getElementById("DataPicker").value;

    if (currentDate != x) {
      setCurrentDate(x);
    } else {
      console.log("Current", currentDate);
    }
    console.log("Current Date", currentDate);
  }, [startDate]);

  // useEffect(() => {
  //   getIncompleteRecording();
  // }, [currentDate]);
  const allPartDetails=(value,recording_id,liveClassId)=>{
    var uploadResultIndexed = [];

    value?.map((data) => {
      console.log("Item", data);
      uploadResultIndexed.push({
        PartNumber: data.PartNumber,
        ETag: data.ETag,
      });
    });
    let uploadedParts=[...uploadResultIndexed]
    uploadedParts.sort((a,b)=>a.PartNumber-b.PartNumber)
    let missingParts=[]
    if(uploadedParts?.length<uploadedParts[uploadedParts.length-1]){
      for(let i=0;i<uploadedParts.length;i++){
        if(uploadedParts[i]?.PartNumber!==(i+1)){
          missingParts.push({part:i+1})
        }
      }
      sendUploadingError(JSON.stringify(missingParts),liveClassid,recording_id,"")
    }
    console.log("uploadResultIndexed", uploadResultIndexed.length);

 
console.log(uploadResultIndexed)
   return [...uploadResultIndexed]

}
  function completeRecordingParts(mFileKey, uploadParts, mUploadId, recordingId) {
    var params = {
      Bucket: bucketName,
      Key: mFileKey,
      MultipartUpload: {
        Parts: uploadParts,
      },
      UploadId: mUploadId,
    };

    var s3CompletePromise = new Promise(function (resolve, reject) {
      s3.completeMultipartUpload(params, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    s3CompletePromise.then(
      function (value) {
        console.log("Completing the record", value);
        updateRecordSuccessToServer(userID, recordingId);
      },
      function (err) {
        console.log("Record uploading failed", err);
        //  alert("Record upload failed please connect with tech support to resolve");
      }
    );
  }

  async function handleCompleteRecording(live_class_id) {
    let uploadId=[]
  
    
    await recordingUpdateToServer(live_class_id,userID);
    await getIncompleteRecording()
     return
    uploadId=uploadId?.data?.live_class_recording_details||[]
  
  
  console.log(uploadId)
  let i=0;
    let promiseArr=[];
  for(let item of uploadId){  
      var params = {
        Bucket: bucketName,
        Key: item?.file_key,
        UploadId: item?.upload_id,
      };
      let s3ListParts = new Promise(function (resolve, reject) {
        s3.listParts(params, function (err, data) {
          if (err) {
            console.debug("Failed List params", err);
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
   promiseArr.push(s3ListParts)
  }
  
  for(let i=0;i<promiseArr.length;i++){
    try{
    let data=await promiseArr[i]
    let parts=data?.Parts||[]
    let uploadResultIndexed=allPartDetails(parts,uploadId[i]?.recording_id,live_class_id)
   let details= await recordEndingPart(
      uploadId[i]?.file_key,
      uploadId[i]?.upload_id,
      [...uploadResultIndexed]
    );
   
    
  }
  catch(e){
    sendUploadingError(e,live_class_id,uploadId[i]?.recording_id,"");
  }
  finally{
    if(i==promiseArr.length-1){
      console.log('successfullty')
       
     await recordingUpdateToServer(live_class_id,uploadId[i]?.file_key?.split('/').pop());
     await getIncompleteRecording()
    }
  }
  
  
  }
    
  }
  async function recordEndingPart(mFileKey, mUploadId, mUploadPart) {

  
    var params = {
  Bucket: bucketName,
  Key: mFileKey,
  MultipartUpload: {
    Parts: mUploadPart,
  },
  UploadId: mUploadId,
};

var s3CompletePromise = new Promise(function (resolve, reject) {
  s3.completeMultipartUpload(params, function (err, data) {
    if (err) {
      reject(err);
    } else {
      resolve(data);
    }
  });
});

return s3CompletePromise


}
  function listRecordingParts(mFileKey, mUploadId, recordId, liveClassId) {
    var params = {
      Bucket: bucketName,
      Key: mFileKey,
      UploadId: mUploadId,
    };

    var s3ListParts = new Promise(function (resolve, reject) {
      s3.listParts(params, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
    //Test Comment
    s3ListParts.then(
      function (value) {
        console.log("Listing Parts", value.Parts.length);
        var uploadResultIndexed = [];
        let totalPartsNumber=[]
        value.Parts.map((data) => {
          uploadResultIndexed.push({
            PartNumber: data.PartNumber,
            ETag: data.ETag,
          });
          totalPartsNumber.push({
            PartNumber: data.PartNumber,
          
          });
        });

        if (value.Parts.length == 0) {//deactivateToServer(liveClassId, recordId)
        }
        else {
          completeRecordingParts(mFileKey, uploadResultIndexed, mUploadId, recordId)
          sendUploadingError(JSON.stringify(totalPartsNumber),liveClassId,recordId,"")
        
        };
      },
      function (err) {
        console.log("Listing Parts failed", err.code);
        if (err.code == "NoSuchUpload") {
          updateRecordSuccessToServer(userID, recordId);
        }

        // alert(err);
      }
    );
  }

  async function getIncompleteRecording() {
    console.log("Getting incomplete recordings");
if(!currentDate)
return
    let response;

    await fetch(
      `${baseUrl}live_class_admins/view_incomplete_recording_lists?user_id=18588&start_date=${currentDate}`
    )
      .then((response) => response.json())
      .then((data) => (response = data));
setShowLoading(false)
    // let response = await AdminApi.IncompleteRecordingList({
    //   user_id: userID,
    //   start_date: currentDate,
    // });
    if (response.status)
      setRecordingList(response.recording_data), setLiveClassID(response.recording_data);
    // console.log("Response", response.data.recording_data);
    // console.log("Response", response.data.recording_data.length);
    // console.log("Recording List", recordingList);
  else{
    setRecordingList([])
  }
  }

  //live_class_admins/update_recording_status

  async function deactivateToServer(live_class_id, recording_id) {
    let response;

    await fetch(
      `${baseUrl}app_students/inactive_recordings?live_class_id=${live_class_id}&recording_id=${recording_id}`
    )
      .then((response) => response.json())
      .then((data) => (response = data));
      setShowLoading(false)
    if (response.status) {
      // alert("Record Uploaded Successfully");
      getIncompleteRecording();
    } else alert("Record upload failed");
  }

  async function updateRecordSuccessToServer(user_id, recording_id,liveClassId) {
    console.log(liveClassId)
    await fetch(
      `${baseUrl}live_class_admins/update_recording_status?user_id=${user_id}&recording_id=${recording_id}`
    )
      .then((response) => response.json())
      .then((data) => (response = data));
      setShowLoading(false)
    if (response.status) {
      // alert("Record Uploaded Successfully");
      getIncompleteRecording();
    } else alert("Record upload failed");
  }

  function fixAllRecordings() {
    recordingList.map((item) => {
      listRecordingParts(item.file_key, item.upload_id, item.recording_id, item.live_class_id);
    });
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = checkOnChange
    ? liveClassid.slice(indexOfFirstPost, indexOfLastPost)
    : recordingList.slice(indexOfFirstPost, indexOfLastPost);
  const totalPosts = recordingList.length;
  //pagination logic
  const pagenumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pagenumbers.push(i);
  }
  const paginate = (num) => {
    setCurrentPage(num);
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pagenumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <div onClick={handleNextbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <div onClick={handlePrevbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }

  const changeLiveClassID = (e) => {
    if (e.keyCode == 13 && e.target.value == "") {
      setOnChange(false);
      getIncompleteRecording();
    } else if (e.keyCode === 13) {
      let y = recordingList.filter((item) => item.live_class_id == e.target.value);

      console.log("Y", y);
      setOnChange(true);
      setLiveClassID(y);
    }
  };

  const isTodaysDate = () => {
    console.log("Todays Class check");
    var inputDate = new Date(currentDate);
    var todaysDate = new Date();
    console.log("Input Date", inputDate);
    console.log("Todays Date", todaysDate);
    return inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0);
  };
  function fixAllRecordings() {
    recordingList.map((item) => {
      handleCompleteRecording(item.live_class_id);
    });
  }

  return (
    <Card>
      <div style={{ display: "flex", gap:"2rem",width:"90%",margin:"auto",marginTop:"1rem"}}>
        <DatePicker
          id="DataPicker"
          className={styles.DatePicker}
          dateFormat="yyyy/MM/dd"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <div className={styles.inputboxStyle}>
          <input
            placeholder="Enter Live Class Id"
            onKeyDown={(e) => {
              changeLiveClassID(e);
            }}
          ></input>
        </div>
      </div>
     
      <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiTypography variant="h6">Incomplete Recording</SuiTypography>
        <SuiTypography variant="h6">Total No of Recordings : {recordingList.length}</SuiTypography>
        {!isTodaysDate() && recordingList?.length>0&&(
          <SuiBadge
            mr={4}
            variant="gradient"
            component="a"
            style={{ cursor: "pointer" }}
            badgeContent="Complete All"
            color="success"
            size="extra-small"
            onClick={() => fixAllRecordings("item.file_key", "item.upload_id", "item.recording_id")}
          />
        )}
      </SuiBox>
      {/* <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiBadge
          mr={4}
          variant="gradient"
          component="a"
          style={{ cursor: "pointer" }}
          badgeContent="Complete All"
          color="info"
          size="large"
          onClick={() => fixAllRecordings()}
        />
      </SuiBox> */}
      {showLoading?<div style={{display:"flex",justifyContent:"center"}}>Loading...</div>:recordingList?.length>0?<SuiBox customClass={classes.tables_table}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <tr>
            <th style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>No</th>
            <th style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>Live Class Id</th>
            <th style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>Key</th>

            <th style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>Complete It</th>
           
          </tr>

          {currentPosts
            // .filter((listItem) => listItem.live_class_id == 76531)
            .map((item, i) => {
              return (
                <>
                  <tr>
                    <td style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>
                      {(currentPage - 1) * postsPerPage + (i + 1)}
                    </td>
                    <td style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>
                      {item.live_class_id}
                    </td>
                  
                    <td style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>
                      {" "}
                      {item.file_key}
                    </td>
                    {/* <td style={{ textAlign: "center", padding: "2px", fontSize: "medium" }}>
                      {" "}
                      {item.parts.length}
                    </td> */}
                    <td>
                      <SuiBadge
                        mr={3}
                        variant="gradient"
                        component="a"
                        style={{ cursor: "pointer" }}
                        badgeContent="Complete It"
                        color="success"
                        size="extra-small"
                        onClick={() =>
                          handleCompleteRecording(
                          
                            item.live_class_id
                          )
                        }
                      />
                    </td>
                   
                  </tr>
                </>
                // <SuiBox key={item.recording_id} display="flex" alignItems="center" px={1} py={0.5}>
                //   <SuiTypography mr={2} variant="h6" textColor="secondary">
                //     {item.recording_id}
                //   </SuiTypography>
                //   <SuiTypography mr={4} variant="h6" textColor="secondary">
                //     {item.file_key}
                //   </SuiTypography>
                //   <SuiTypography mr={4} variant="h6" textColor="secondary">
                //     {item.parts.length}
                //   </SuiTypography>
                //   <SuiBadge
                //     mr={4}
                //     variant="gradient"
                //     component="a"
                //     style={{ cursor: "pointer" }}
                //     badgeContent="Complete It"
                //     color="success"
                //     size="extra-small"
                //     onClick={() =>
                //       listRecordingParts(item.file_key, item.upload_id, item.recording_id)
                //     }
                //   />
                //   <SuiBadge
                //     mr={4}
                //     variant="gradient"
                //     component="a"
                //     style={{ cursor: "pointer" }}
                //     badgeContent="Mark as Completed"
                //     color="info"
                //     size="extra-small"
                //     onClick={() => updateRecordSuccessToServer(userID, item.recording_id)}
                //   />
                // </SuiBox>
              );
            })}
        </table>
      </SuiBox>:<div className={styles.flexCenterNotFound}>No Recording Found...</div>}
     {!showLoading&& <div className={styles.button}>
        <SuiButton
          variant="gradient"
          color="info"
          size="small"
          circular="true"
          iconOnly="true"
          onClick={handlePrevbtn}
          disabled={currentPage == pagenumbers[0] ? true : false}
        >
          -
        </SuiButton>
        {pageDecrementBtn}
        {pagenumbers.map((number, i) => {
          if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
              <SuiButton
                key={number}
                onClick={() => {
                  paginate(number);
                }}
                variant="gradient"
                color="info"
                size="small"
                circular="true"
                iconOnly="true"
                style={i == currentPage - 1 ? { background: "yellow" } : { background: "white" }}
              >
                {number}
              </SuiButton>
            );
          } else {
            return null;
          }
        })}
        {pageIncrementBtn}
        <SuiButton
          variant="gradient"
          color="info"
          size="small"
          circular="true"
          iconOnly="true"
          onClick={handleNextbtn}
          disabled={currentPage == pagenumbers[pagenumbers.length - 1] ? true : false}
        >
          +
        </SuiButton>
      </div>}
      <div ref={vref}></div>
    </Card>
  );
}

export default IncompleteClassRecordingDetails;
