import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import { useState, useEffect } from "react";
import SuiButton from "components/SuiButton";

import styles from "./charts.module.css";

function DailyChart({ dataTochart, change, changechart }) {
  const [week_1, setWeek1] = useState([]);
  const [week_2, setWeek2] = useState([]);
  const [week_3, setWeek3] = useState([]);
  const [week_4, setWeek4] = useState([]);

  useEffect(() => {
    let new_obj = {};
    let week1 = [];
    let week2 = [];
    let week3 = [];
    let week4 = [];
    let num_data = dataTochart.map((e) => Number(e.created_at.trim().split(" ")[0]));
    for (let i = 0; i < num_data.length; i++) {
      if (new_obj[num_data[i]] == undefined) {
        new_obj[num_data[i]] = 1;
      } else {
        new_obj[num_data[i]] = new_obj[num_data[i]] + 1;
      }
    }
    for (let key in new_obj) {
      if (key >= 1 && key <= 7) {
        week1.push({ key, count: new_obj[key] });
      }
      if (key >= 8 && key <= 14) {
        week2.push({ key, count: new_obj[key] });
      }
      if (key >= 15 && key <= 21) {
        week3.push({ key, count: new_obj[key] });
      }
      if (key > 21) {
        week4.push({ key, count: new_obj[key] });
      }
    }
    setWeek1(week1);
    setWeek2(week2);
    setWeek3(week3);
    setWeek4(week4);
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.nav_daily}>
        <SuiButton
          onClick={() => {
            change();
          }}
        >
          Show Weekly Data
        </SuiButton>
        <SuiButton
          onClick={() => {
            changechart();
          }}
        >
          Go back
        </SuiButton>
      </div>
      <div className={styles.children}>
        <DefaultLineChart
          title="Week 1 Data"
          height="10rem"
          chart={{
            labels: week_1.map((e) => e.key),
            datasets: [
              {
                label: "Errors",
                color: "info",
                data: week_1.map((e) => e.count),
              },
            ],
          }}
        />

        <DefaultLineChart
          title="Week 2 Data"
          height="10rem"
          chart={{
            labels: week_2.map((e) => e.key),
            datasets: [
              {
                label: "Errors",
                color: "info",
                data: week_2.map((e) => e.count),
              },
            ],
          }}
        />

        <DefaultLineChart
          title="Week 3 Data"
          height="10rem"
          chart={{
            labels: week_3.map((e) => e.key),
            datasets: [
              {
                label: "Errors",
                color: "info",
                data: week_3.map((e) => e.count),
              },
            ],
          }}
        />
        <DefaultLineChart
          title="Week 4 Data"
          height="10rem"
          chart={{
            labels: week_4.map((e) => e.key),
            datasets: [
              {
                label: "Errors",
                color: "info",
                data: week_4.map((e) => e.count),
              },
            ],
          }}
        />
      </div>
    </DashboardLayout>
  );
}
export default DailyChart;
