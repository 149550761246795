import { baseUrl } from "api";
import ImageReview from "layouts/daywise/Components/ViewMoreSid/RaiseTicketForm/ImageReview";
import React, { useState } from "react";
import styles from "./daywiseRaiseTicket.module.css";
export default function ViewMoreDetailsRaiseTicket({ onClick, viewDatas }) {
  const [datas, setDatas] = useState({});
  const [isLoading, setIsLoading] = useState(false);
const [openImageModal,setOpenImageModal]=useState(false)
const [currentImageUrl,setCurrentImageUrl]=useState("")
  const closeModal = () => {
    
    onClick();
  };
  const handleShowImages=(url)=>{
    setCurrentImageUrl(url)
    setOpenImageModal(true)
  }
  const handleImagePreviewClose=()=>{
    setCurrentImageUrl("")
    setOpenImageModal(false)
  }
  return isLoading ? (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <h1>Loading...</h1>
      </div>
    </div>
  ) : (
    <div>
      <>
        <div>
          <div className={styles.modalBackground}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              Error Details
            </div>
            {openImageModal && (
          <ImageReview src={currentImageUrl} onClick={handleImagePreviewClose} />
        )}
            {
              <div className={styles.modalContainer}>
                <div className={styles.titleCloseBtn}>
                  <button
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    X
                  </button>
                </div>

                <div className={styles.secondmainContainer}>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Error Id</h3>

                    
                      <span>
                      <span>{viewDatas?.id ?? <b>Not Present</b>}</span>
                      </span>
                   
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Live Class Id</h3>
                   
                      <span>
                      <span>{viewDatas?.live_class_id ?? <b>Not Present</b>}</span>
                      </span>
                 
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Error Type</h3>
                    <span>{viewDatas?.error_type ?? <b>Not Present</b>}</span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Error Description</h3>
                    <span>{viewDatas?.error_description ?? <b>Not Present</b>}</span>
                  </div>

                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Operating System</h3>
                    <span>{viewDatas?.operating_system ?? <b>Not Present</b>}</span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Devices</h3>
                    <span>
                    <span>{viewDatas?.device?? <b>Not Present</b>}</span>
                    </span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Browser</h3>
                    <span>{viewDatas?.browser ?? <b>Not Present</b>}</span>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>ScreenShots</h3>
                    <div className={styles.screenShotsContainer}>{viewDatas?.images?.map((item,i)=><img src={baseUrl+item} className={styles.screenShots} key={i} onClick={()=>handleShowImages(baseUrl+item)}/>)}</div>
                  </div>
                </div>
                {/* </>
                );
              })} */}
              </div>
            }
          </div>
        </div>
      </>
    </div>
  );
}
