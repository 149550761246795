/* eslint-disable */
import React, { useState } from "react";
import styles from "./custom2.module.css";
import { useEffect } from "react";
import { getLiveClassDetails } from "api";

export default function ErrorLogView({
  childforRecord,
  errorlogsfulldata,
  errordetailsdata,
  liveClassdata,
  roomSiddata,
  participantDetails,
  liveClassId
}) {
  const [classdetails, setClassDetails] = useState([]);

  

  useEffect(() => {
    
    filterData();
  }, []);

  const closeModal = () => {
    console.log('closing')
    childforRecord();
  };

  const filterData = async () => {
    
    let afterfilter = liveClassdata.filter((item) => {
      if (item.id == errordetailsdata) {
        return item;
      }
    });
    
    setClassDetails(afterfilter);
    if(afterfilter?.length<1)
    fetchDetails()
  };
console.log(classdetails)

const fetchDetails=async ()=>{
try{
let data=await getLiveClassDetails(18588,liveClassId)
data=await data.json()
console.log(data?.status)
if(data?.status){
  
 
  let teacherName=(data?.live_class_details?.teacher?.first_name||"")+" "+(data?.live_class_details?.teacher?.last_name||"")
  let students=data?.live_class_details?.students?.map((item)=>{
    let obj={
      name:(item?.first_name||"")+" "+(item?.last_name||"")
    }
    return {...obj}
  })
  let times=data?.live_class_details?.start_time?.split(' ')
  times.shift()
  times=times.join(' ')
  let obj={id:liveClassId,teacher:teacherName,students,time:times||""}
  let temp=[{...obj}]
  setClassDetails(temp)
}

}
catch(e){

}
}
  return (
    <div>
      <div className={styles.modalBackground} style={{opacity:1}}>
        <div className={styles.modalContainer}>
          <div className={styles.titleCloseBtn}>
            <button style={{cursor:"pointer"}}
              onClick={() => {
                closeModal();
              }}
            >
              X
            </button>
          </div>

          <div>
            <div className={styles.mainHeading}>
              <h3 className={styles.mainHeading}>Class Details</h3>
              <div style={{ marginLeft: "24px" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    tableLayout: "auto",
                  }}
                >
                  <tr>
                    <th>Teacher Name</th>
                    <th>Student Name</th>
                    <th>Live Class ID</th>
                    <th>Time</th>
                  </tr>
                  {classdetails?.map((item, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>{item.teacher}</td>
                          <td
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              verticalAlign: "baseline",
                            }}
                          >
                            {item?.students.map((e,i) => {
                              return (
                                <>
                                  <td key={i}>{e.name}</td>
                                </>
                              );
                            })}
                          </td>
                          <td>{item.id}</td>
                          <td>{item.time}</td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>

            <div className={styles.mainHeading}>
              <h3 className={styles.mainHeading}>Room SID</h3>
              <div className={styles.roomSiddetails}>
                {roomSiddata.length > 0 ? (
                  roomSiddata?.map((e, i) => {
                    return (
                      <>
                        <div key={i}>
                          <div className={styles.roomSiddetailsdiv}>{e.room_sid}</div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div>No Data Present</div>
                )}
              </div>
            </div>
            <div className={styles.mainHeading}>
              <h3 className={styles.mainHeading}>Error Details</h3>

              <div className={styles.roomSiddetailsss}>
                <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "fixed" }} className={styles.errorLogViewTable}>
                  <tr>
                    <th>Error Message</th>
                    <th>Error Code</th>
                  </tr>
                
                {errorlogsfulldata.length > 0 ? (
                  errorlogsfulldata?.map((e,i) => {
                    return (
                      <tr key={i}>
                        <td >{e.error_message}</td>
                        <td >{String(e.error_code)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <div>No data present</div>
                )}</table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
