/* eslint-disable no-unused-vars */
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import IncompleteRecordings from "./components/IncompleteRecordingList";

import { useEffect } from "react";

function Recordings() {
  useEffect(() => {
    // completeRecordingParts();
  }, []);

  return (
    <>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <IncompleteRecordings />
      </DashboardLayout>
    </>
  );
}
export default Recordings;
