import { submitErrorLog } from "api";
import React from "react";

import { Button, Col, Container, Row, Image, Modal } from "react-bootstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errortype: "", errorinfo: "" };
  }

  refreshPage = () => {
    window.location.reload();
  };

  componentDidCatch(error, info) {
    console.log("Error is this", error);
    console.log("Info is", info);
    let errors = error
  //errors=encodeURI.URI(errors)
    this.setState({ hasError: true, errortype: error, errorinfo: info });
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let userID = 18588
    let liveClassID = 3
    errors="Room not found"
    let codes=53100
  //  let result=submitErrorLog(userID, liveClassID, errors,codes,0);
   
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <Modal.Dialog centered>
            <Modal.Body
              style={{ padding: "40px", border: "1px solid #E2E4EE" }}
            >
              <Row>
                <Col md={12} className="text-center">
                  <h3
                    style={{ color: "#233584", fontWeight: "bold" }}
                    className="text-center"
                  >
                    Error
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  <Image
                    style={{ width: "150px" }}
                    src={
                      process.env.PUBLIC_URL + "/static/media/Error-image.png"
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="text-center">
                  <h6 className="page-sub-head text-center">Uh Ohhhh</h6>
                </Col>
              </Row>
              <br />

              <Container>
                <Row className="text-right">
                  <Col md={12} className="text-center">
                    <Button
                      style={{ margin: "0 10px" }}
                      className="primary-button"
                      variant="secondary"
                      onClick={this.refreshPage}
                    >
                      Retry
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal.Dialog>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
