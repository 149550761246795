import { baseUrl } from "api";
import ViewMoreForSid from "layouts/daywise/Components/ViewMoreSid/ViewMoreSid";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Charts from "../Charts";
import SuiBox from "components/SuiBox";
import styless from "layouts/tables/styles";
import { useEffect, useState } from "react";
import styles from "../../custom.module.css";
export default function ViewMoreSidErrorLog({ liveClassId, currentDate, errorData, message,onClick,participantDetails }) {
  const classes = styless();
  const [errorDetailsFullData, setErrorDetailsFullData] = useState([]);
  const [errorDetailsData, setErrorDetailsData] = useState("");
  const [errorDetailsModal, showErrorDetailsModal] = useState(false);
  const [roomSidData, setRoomSidData] = useState([]);
  const [liveClassData, setLiveClassData] = useState([]);
  const [errorshownotdata, setErrorDetailsNoDataerror] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const showErrorLogsDetails = async (live_class_id, userId) => {
    showErrorDetailsModal(true);
    setErrorDetailsData(live_class_id);

    fetch(
      `${baseUrl}live_class_admins/live_class_room_sids_and_error_logs?user_id=${userId}&live_class_id=${live_class_id}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.status) {
          let errorLogs = [];
          let message = "";
          let sortdata = data.error_logs.sort((a, b) =>
            a.error_message > b.error_message ? 1 : b.error_message > a.error_message ? -1 : 0
          );

          errorLogs = sortdata.filter((item) => {
            if (message == "" || item.error_message != message) {
              message = item.error_message;
              return 1;
            } else {
              return 0;
            }
          });
          setErrorDetailsFullData(errorLogs);

          setRoomSidData(data.room_sids);
        } else {
        }
      });
  };
  const collectAllData = async (liveClassId, dates) => {
    const userId = localStorage.getItem("user");
    await showErrorLogsDetails(liveClassId, userId);
    //await getListingData(userId, dates);
  };
  const [datas, setDatas] = useState([]);
  const filteringOutData = (liveClassId, response) => {
    let arr = response.filter((item) => String(item.id).trim() == String(liveClassId).trim());
    setLiveClassData([...arr]);
  };
  useEffect(() => {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const year = [2022];
    let y = errorData.filter(
      (item) =>
        String(item.live_class_id).trim() === String(liveClassId).trim() &&
        item.message.trim() === message.trim()
    );
    let dates = y[0].created_at;
    dates = dates.trim().split(" ");
    dates[0] = days[Number(dates[0]) - 1];
    dates[1] = months[dates[1].trim()];
    dates[2] = "20" + dates[2].trim();
    let temp = dates[0];
    dates[0] = dates[2];
    dates[2] = temp;
    dates = dates.join("/");
    collectAllData(liveClassId, dates);
  }, []);
const [datas2,setDatas2]=useState([])
  async function getListingData(userId, currentDate) {
    let response;

    await fetch(
      `${baseUrl}live_class_admins/daywise_classes?user_id=${userId}&start_date=${currentDate}`
    )
      .then((response) => response.json())
      .then((data) => (response = data));

    if (response?.status) {
      setLiveClassData(response?.live_class_data);
     setDatas2(response?.live_class_data)
      filteringOutData(liveClassId, response?.live_class_data);
      setShowLoading(false);
    } else {
      filteringOutData(liveClassId, []);
      setShowLoading(false)
    }
  }
 
  const [errorshownotdataroomsid, setErrorDetailsNoDataroomsid] = useState(true);
  
  const handleClose=()=>{
    onClick()
  }
  return showLoading?  <div className={styles.modalBackground}>
  <div className={styles.modalContainer}>
    <div className={styles.titleCloseBtn}>
      <button
        onClick={handleClose}
      >
        X
      </button>
    </div>
    <h1>Loading...</h1>
  </div>
</div>:!liveClassData.length > 0 ? (
    <div style={{ zIndex: "1", padding: "10px" }}>
    <DashboardLayout >
     <SuiBox customClass={classes.tables_table} >
         
        <ViewMoreForSid
      childforRecord={handleClose}
      liveclassid={liveClassId}
      roomSiddata={roomSidData}
      roomSiddata2={roomSidData}
      errordetailsdata={errorDetailsData}
      liveClassdata={datas2}
      errorlogsfulldata={errorDetailsFullData}
      errorshowdata={errorshownotdata}
      roomshowdata={errorshownotdataroomsid}
      x={"errorMessage"}
      y={"errorlogsdata"}
      recordingData={liveClassData[0]}
      errorLogsViewSid={true}
      participantDetails={participantDetails}
      
    />

       </SuiBox>
     
      
    </DashboardLayout>
  </div>
  ) : (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.titleCloseBtn}>
          <button
            onClick={handleClose}
          >
            X
          </button>
        </div>
        <h1>No Data Present...</h1>
      </div>
    </div>
  );
}
