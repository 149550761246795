/* eslint-disable no-debugger */
import axios from "axios";
import styles from "../custom.module.css";
import { useEffect, useState } from "react";
import SuiBadge from "components/SuiBadge";
import { baseUrl } from "api";

function Modal({ child, modalData }) {
  const [data, setmodalData] = useState([]);
  const [game, setGame] = useState(false);
  const [imageset, setFinalImage] = useState([]);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    let live_class_id = modalData[0];
    let live_user_id = modalData[1];
    (async function getData() {
      fetch(
        `${baseUrl}live_class_admins/live_class_emotions?user_id=18588&live_class_id=${live_class_id}&live_user_id=${live_user_id}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            console.log("Model", data);
            setmodalData(data);
            setGame(true);
          }
        });
    })();
  }, [imageset]);

  const filterEmotions = (e) => {
    console.log("E", e);
    var intialValue = 0.0001;
    console.log("Data", data);
    var x = data.emotion_data;
    if (data.length == 0) return;
    console.log("Emotionm", data);

    var final = x.filter((item) => (item[e] > intialValue ? 1 : 0));

    setShowImage(true);
    console.log("Happires", final);
    setFinalImage([...final]);
  };

  // useEffect(() => {
  //   setGame(true);
  // }, [data]);
  const closeModal = () => {
    child();
  };
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.titleCloseBtn}>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            X
          </button>
        </div>
        <div className={styles.emotionsbuttons}>
          <div>
            <SuiBadge
              mr={4}
              variant="gradient"
              component="a"
              style={{ cursor: "pointer" }}
              badgeContent="Happy"
              color="primary"
              size="medium"
              onClick={() => filterEmotions("happiness")}
            />
          </div>
          <div>
            <SuiBadge
              mr={4}
              variant="gradient"
              component="a"
              style={{ cursor: "pointer" }}
              badgeContent="Angry"
              color="error"
              size="medium"
              onClick={() => filterEmotions("anger")}
            />
          </div>
          <div>
            <SuiBadge
              mr={4}
              variant="gradient"
              component="a"
              style={{ cursor: "pointer" }}
              badgeContent="Fear"
              color="warning"
              size="medium"
              onClick={() => filterEmotions("fear")}
            />
          </div>
          <div>
            <SuiBadge
              mr={4}
              variant="gradient"
              component="a"
              style={{ cursor: "pointer" }}
              badgeContent="Sadness"
              color="dark"
              size="medium"
              onClick={() => filterEmotions("sadness")}
            />
          </div>
          <div>
            <SuiBadge
              mr={4}
              variant="gradient"
              component="a"
              style={{ cursor: "pointer" }}
              badgeContent="Neutral"
              color="secondary"
              size="medium"
              onClick={() => filterEmotions("neutral")}
            />
          </div>
          <div>
            <SuiBadge
              mr={4}
              variant="gradient"
              component="a"
              style={{ cursor: "pointer" }}
              badgeContent="Surprise"
              color="success"
              size="medium"
              onClick={() => filterEmotions("surprise")}
            />
          </div>
        </div>
        {/* 
        {imageset ? (
          <div className={styles.modal_mappedsecond}>
            <div className={styles.mainImageshowdiv}>
              <img className={styles.mainImageshow} src={imageset} />
            </div>
          </div>
        ) : (
          <div className={styles.modal_mapped}>No Data Present</div>
        )} */}

        {game && !showImage ? (
          <div className={styles.modal_mapped}>
            {data.emotion_data?.map((e, i) => (
              <>
                <div key={i} className={styles.allImagesgridalign}>
                  <div className={styles.allImagesgridaligncolumn}>
                    <img className={styles.imageWidthAdjust} src={e.image}></img>
                    <div className={styles.otherDetailsofchild}>Anger:{e.anger}</div>
                    <div className={styles.otherDetailsofchild}>Fear:{e.fear}</div>
                    <div className={styles.otherDetailsofchild}>Happiness:{e.happiness}</div>
                    <div className={styles.otherDetailsofchild}>Neutral:{e.neutral}</div>
                    <div className={styles.otherDetailsofchild}>Sadness:{e.sadness}</div>
                    <div className={styles.otherDetailsofchild}>Surprise:{e.surprise}</div>
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : showImage ? (
          <div className={styles.modal_mapped}>
            {imageset.map((e, i) => (
              <>
                <div key={i} className={styles.allImagesgridalign}>
                  <div className={styles.allImagesgridaligncolumn}>
                    <img className={styles.imageWidthAdjustsecond} src={e.image}></img>
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          // <div className={styles.modal_mappedsecond}>
          //   <div className={styles.mainImageshowdiv}>
          //     <img className={styles.mainImageshow} src={imageset} />
          //   </div>
          // </div>
          <div className={styles.modal_mapped}>No Data Present</div>
        )}
      </div>
    </div>
  );
}

export default Modal;
