import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import SuiButton from "components/SuiButton";
import styles from "../custom.module.css";
import SuiTypography from "components/SuiTypography";
import Modal from "./Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Charts from "./Charts";
import SuiBox from "components/SuiBox";
import styless from "layouts/tables/styles";
import { baseUrl } from "api";
import { Button } from "@mui/material";
import { closeErrorLog } from "api";
function Grouping({ insidegroup }) {
  const classes = styless();
  const [startDate, setStartDate] = useState(new Date());
  const [dataTochart, setDatatoChart] = useState([]);
  const [charts, setCharts] = useState(false);
  const [smonth, setMonth] = useState("");
  const [syear, setYear] = useState("");
  const [data, setTotaldata] = useState([]);
  const [modal, showmodal] = useState(false);
  const [modaldata, setmodalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data_teacher, setDataTeacher] = useState({});
  const [liveClassid, setLiveClassID] = useState([]);
  const [checkOnChange, setOnChange] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  useEffect(() => {
    let x = document.getElementById("DataPicker").value;
    let [temp1, temp2] = x.split("/");
    let temp = [temp2, temp1, "01"];
    temp = temp.join("/");
    setCurrentDate(temp);
    let [month, year] = x.split("/").map((e) => (e * 1).toString());
    setMonth(month);
    setYear(year);
    setLoading(true);
  }, [startDate]);

  var finalData = checkOnChange ? liveClassid : data;
  useEffect(() => {
    fetch(
      `${baseUrl}live_class_admins/live_class_error_logs?user_id=18588&month=${smonth}&year=${syear}`
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setErrorData(data.error_log_data);
        if (data.status) {
          let new_obj = {};
          const datesArray = {};
          let teacher_obj = {};
          for (let i = 0; i < data.error_log_data.length; i++) {
            if (new_obj[data.error_log_data[i].message] == undefined) {
              new_obj[data.error_log_data[i].message] = {
                count: 1,
                arr: [data.error_log_data[i].live_class_id],
                codes:data?.error_log_data[i]?.code,
                status:data?.error_log_data[i]?.status
              };
              datesArray[data.error_log_data[i].message] = {
                dates: [data.error_log_data[i].created_at],

              };
            } else {
              new_obj[data.error_log_data[i].message] = {
                count: new_obj[data.error_log_data[i].message].count + 1,
                arr: [
                  ...new_obj[data.error_log_data[i].message].arr,
                  data.error_log_data[i].live_class_id,
                ],
                codes:data?.error_log_data[i]?.code,
                status:data?.error_log_data[i]?.status
              };
              datesArray[data.error_log_data[i].message] = {
                dates: [
                  ...datesArray[data.error_log_data[i].message].dates,
                  data.error_log_data[i].created_at,
                ],
              };
            }
          }
          for (let keys in new_obj) {
            let dates = datesArray[keys]?.dates?.length || 1;
            new_obj[keys].created_at = datesArray[keys]?.dates[0];
            new_obj[keys].lastTime = datesArray[keys]?.dates[dates - 1];
          }

          for (let i = 0; i < data.error_log_data.length; i++) {
            if (teacher_obj[data.error_log_data[i].live_class_id] == undefined) {
              teacher_obj[data.error_log_data[i].live_class_id] = {
                teacher: data.error_log_data[i].teacher,
                student: data.error_log_data[i].students,
              };
            }
          }

          let entries = Object.entries(new_obj);

          entries = entries.map((e) => [
            e[0],
            e[1].count,
            e[1].arr,
            e[1].created_at,
            e[1].lastTime,
            e[1]?.codes,
            e[1]?.status
          ]);

          let sorted = entries.sort((a, b) => b[1] - a[1]);

          setDataTeacher(teacher_obj);
          setDatatoChart(data.error_log_data);

          setTotaldata(sorted);
        } else {
          return;
        }
      });
  }, [smonth]);
  const [currentMessage, setCurrentMessage] = useState("");
  const showinfo = (e) => {
    showmodal(true);
    setCurrentMessage(e[0]);
    setmodalData(e[2]);
  };
  const child = () => {
    showmodal(false);
  };
  const handleToggle = () => {
    insidegroup();
  };
  const changechart = () => {
    setCharts(false);
  };
  const [errorData, setErrorData] = useState([]);

  const changeLiveClassID = async (e) => {
    if (e.keyCode == 13 && e.target.value == "") {
      fetch(
        `${baseUrl}live_class_admins/live_class_error_logs?user_id=18588&month=${smonth}&year=${syear}`
      )
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);

          setErrorData(data.error_log_data);

          if (data.status) {
            let new_obj = {};
            const datesArray = {};
            let teacher_obj = {};
            for (let i = 0; i < data.error_log_data.length; i++) {
              if (new_obj[data.error_log_data[i].message] == undefined) {
                new_obj[data.error_log_data[i].message] = {
                  count: 1,
                  arr: [data.error_log_data[i].live_class_id],
                  codes:data?.error_log_data[i]?.code,
                  status:data?.error_log_data[i]?.status
                };
                datesArray[data.error_log_data[i].message] = {
                  dates: [data.error_log_data[i].created_at],
                };
                
              } else {
                new_obj[data.error_log_data[i].message] = {
                  count: new_obj[data.error_log_data[i].message].count + 1,
                  arr: [
                    ...new_obj[data.error_log_data[i].message].arr,
                    data.error_log_data[i].live_class_id,


                  ],
                  codes:data?.error_log_data[i]?.code,
                  status:data?.error_log_data[i]?.status

                };
                datesArray[data.error_log_data[i].message] = {
                  dates: [
                    ...datesArray[data.error_log_data[i].message].dates,
                    data.error_log_data[i].created_at,
                  ],
                };
              }
            }
            for (let keys in new_obj) {
              let dates = datesArray[keys]?.dates?.length || 1;
              new_obj[keys].created_at = datesArray[keys]?.dates[0];
              new_obj[keys].lastTime = datesArray[keys]?.dates[dates - 1];
            }
  
            for (let i = 0; i < data.error_log_data.length; i++) {
              if (teacher_obj[data.error_log_data[i].live_class_id] == undefined) {
                teacher_obj[data.error_log_data[i].live_class_id] = {
                  teacher: data.error_log_data[i].teacher,
                  student: data.error_log_data[i].students,
                };
              }
            }
            let entries = Object.entries(new_obj);
           
            entries = entries.map((e) => [e[0], e[1].count, e[1].arr,e[1]?.created_at,e[1]?.lastTime,e[1]?.codes,e[1]?.status]);
            let sorted = entries.sort((a, b) => b[1] - a[1]);
            setDataTeacher(teacher_obj);
            setDatatoChart(data.error_log_data);
            setTotaldata(sorted);
            setOnChange(false);
          } else {
            return;
          }
        });
        
    } else if (e.keyCode === 13) {
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i][0].includes(e.target.value)) {
          arr.push(data[i]);
          setOnChange(true);
          setLiveClassID(arr);
        }
      }
    }
  };

  const [currentLiveClassId, setCurrentLiveClassId] = useState("");
  const handleChangeLiveClassId = (liveClassId) => {
    setCurrentLiveClassId(liveClassId);
  };
  const handleCloseDetailsLogs = () => {
    setCurrentLiveClassId("");
  };
const handleCloseErrorLogs=async (id)=>{
try{
let y=confirm("Do you really want to close this error.")
let userId=localStorage.getItem('user')
if(y)
{
  let result=await closeErrorLog(userId,id)
 result=await changeLiveClassID({keyCode:'13',target:{
   value:""
 }})
  console.log(result)
}
}
catch(e){
console.log(error,'239')
}
}
  return (
    <>
      {charts ? (
        <Charts dataTochart={dataTochart} changechart={changechart} />
      ) : (
        <div>
          {modal && (
            <Modal
              child={child}
              modalData={modaldata}
              data_teacher={data_teacher}
              currentDate={currentDate}
              errorData={errorData}
              currentMessage={currentMessage}
              onClick={handleChangeLiveClassId}
              onClose={handleCloseDetailsLogs}
            />
          )}
          <div
            style={{
              zIndex: "1",
              padding: "10px",
              display: `${currentLiveClassId ? "none" : "block"}`,
            }}
          >
            <DashboardLayout>
              <div className={styles.sui_button}>
                <div>
                  <DatePicker
                    id="DataPicker"
                    className={styles.DatePicker}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </div>
                <div>
                  <input
                    className={styles.datePickerSecond}
                    placeholder="Enter Message"
                    onKeyDown={(e) => {
                      changeLiveClassID(e);
                    }}
                    // onChange={(e) => {
                    //   handleChange(e);
                    // }}
                  ></input>
                </div>
                <div>
                  <SuiButton
                    onClick={() => {
                      setCharts(true);
                    }}
                  >
                    Show Charts
                  </SuiButton>
                </div>
                <div>
                  <SuiButton
                    variant="gradient"
                    color="success"
                    onClick={() => {
                      handleToggle();
                    }}
                  >
                    Show Indiviual Errors
                  </SuiButton>
                </div>
              </div>

              {loading ? (
                <h1 style={{ marginTop: "50px" }}>Fetching the data.....</h1>
              ) : (
                <SuiBox customClass={classes.tables_table}>
                  <table className={styles.errorTable}>
                    <tr>
                      <th>No</th>
                      <th>Message</th>
                      <th>Open Date</th>
                      <th>Last Issued Date</th>
                      <th>Count</th>
                      <th>Status</th>
                      <th>Action/Status</th>
                    </tr>

                    {finalData?.map((e, i) => (
                      <tr>
                        <td>
                          <SuiTypography variant="caption" style={{ color: "black" }}>
                            {i + 1}
                          </SuiTypography>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            showinfo(e);
                          }}
                        >
                          <SuiTypography variant="caption" style={{ fontSize: "15px" }}>
                            {e[0]}
                          </SuiTypography>
                        </td>
                        <td>{e[3]}</td>
                        <td>{e[4]}</td>

                        <td>
                          <SuiTypography
                            variant="caption"
                            style={{ fontSize: "15px", color: "red",cursor: "pointer" }}
                            
                            onClick={() => {
                              showinfo(e);
                            }}
                          >
                            {e[1]}
                          </SuiTypography>
                        </td>
                        <td>
                          <SuiTypography
                            variant="caption"
                            style={{ fontSize: "15px" }}
                          >
                            {e[6]}
                          </SuiTypography>
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            style={{ color: "white", backgroundColor: "limegreen" }}
                            onClick={()=>handleCloseErrorLogs(e[5])}
                          >
                            Close Status
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </SuiBox>
              )}
            </DashboardLayout>
          </div>
        </div>
      )}
    </>
  );
}
export default Grouping;
