/* eslint-disable react/prop-types */
import Pagination from "./Components/Pagination";
import { useState } from "react";
import Grouping from "./Components/Grouping";
import styles from "./custom.module.css";

function Errorlogs() {
  const [toggle, setToggle] = useState(true);
  const insidepage = () => {
    setToggle(true);
  };
  const insidegroup = () => {
    
    setToggle(false);
  };
  console.log('inside errorlogs',toggle)
  return (
    <>{toggle ? <Grouping insidegroup={insidegroup} /> : <Pagination insidepage={insidepage} />}</>
  );
}

export default Errorlogs;
