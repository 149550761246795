import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useEffect, useState, useRef } from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import "react-datepicker/dist/react-datepicker.css";
import styless from "layouts/tables/styles";
import styles from "./custom.module.css";
import DatePicker from "react-datepicker";
import SuiButton from "components/SuiButton";
import Modal from "./Components/Modal";
import ModalforRecord from "./Components/ModalforRecord";
import Card from "@mui/material/Card";
import ViewMoreForSid from "./Components/ViewMoreSid/ViewMoreSid";
import { enableLiveClass } from "api";
import { disableLiveClass } from "api";
import { Button } from "@mui/material";
import { JoinLiveClass } from "api";
import { baseUrl } from "api";
import { Redirect, useHistory } from "react-router-dom";
import DaywiseStatusField from "./Components/DaywiseStatusField";
import HTMLReactParser from "html-react-parser";
let userID = 18588;
function daywise() {
  const classes = styless();
  const vref = useRef(null);
  const [liveClassdata, setLiveClassData] = useState([]);
  const [enableDisableStatus, setEnableDisableStatus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [showloading, setShowLoading] = useState(true);
  const [showData, setShowNoData] = useState(false);
  const [modal, showmodal] = useState(false);
  const [modalRecord, showRecordModal] = useState(false);
  const [errordetailsmodal, showErrorDetailsModal] = useState(false);
  const [roomsiddetailsmodal, showRoomSidDetailsModal] = useState(false);
  const [roomsiddata, setRoomSidData] = useState([]);
  const [modaldata, setmodalData] = useState([]);
  const [recordingdata, setRecordingData] = useState("");
  const [errordetailsdata, setErrorDetailsData] = useState("");
  const [errorshowsid, setsidshowdata] = useState("");
  const [selectedClient, setSelectedClient] = useState("general"); //default value
  const [liveClassid, setLiveClassID] = useState([]);
  const [checkOnChange, setOnChange] = useState(false);
  const [data, setErrorDetailsFullData] = useState([]);
  const [errorshownotdata, setErrorDetailsNoDataerror] = useState(true);
  const [errorshownotdataroomsid, setErrorDetailsNoDataroomsid] = useState(true);
  const [errorlogsdata, seterrorlogsdata] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const [classStatus, setClassStatus] = useState([]);
  const [roomSiddata, setRoomSidDataforDetails] = useState([]);
  const [todayDate, setTodayDate] = useState("");
  const [disabledJoined,setDisabledJoined]=useState(true)
  const [mainData,setMainData]=useState([])
  useEffect(() => {
    vref.current.scrollIntoView({ behavior: "smooth" });
  }, [currentPage]);

  useEffect(() => {
    var x = document.getElementById("DataPicker").value;
    if (!todayDate) setTodayDate(x);

    if (currentDate != x) {
      setCurrentDate(x);
    } else {
    }
    let tempDate=new Date()?.toLocaleDateString()
    tempDate=tempDate.split('/')
    tempDate.unshift(tempDate[tempDate?.length-1])
    tempDate.pop()
    tempDate=tempDate.join('/')
    let tempDate2=x.split('/')
    for(let i=0;i<tempDate2.length;i++)
    tempDate2[i]=Number(tempDate2[i])
    tempDate2=tempDate2.join('/')
    setDisabledJoined(!(tempDate2===tempDate))
  }, [startDate]);

  useEffect(async () => {
    setClassStatus([]);
    setShowLoading(true);
    setSelectedClient("general");
    setInputChange("");
    setCurrentPage(1);

    await getListingData();
    setOnChange(false);

    // axios
    //   .get(
    //     `https://begalileo.com/live_class_admins/daywise_classes?user_id=18588&start_date=${currentDate}`
    //   )
    //   .then((res) => {
    //
    //     setLiveClassData(res.data.live_class_data);
    //     setShowLoading(false);
    //   })
    //   .catch((error) => {
    //
    //   });
  }, [currentDate, roomSiddata]);

  async function getListingData() {
    try {
      let response;
      if (!currentDate) return;
      await fetch(
        `${baseUrl}live_class_admins/daywise_classes?user_id=18588&start_date=${currentDate}`
      )
        .then((response) => response.json())
        .then((data) => (response = data));

      // let response = await AdminApi.dayWiseList({
      //   user_id: userID,
      //   start_date: currentDate,
      // });
      setShowLoading(false);
      if (response?.status) {
        response.live_class_data =
        response?.live_class_data?.filter(
          (item) =>!( String(item?.status)?.trim() === "Completed"&&((item?.recordings?.length || 0)<1))
        ) || [];
        regularDemoClassCount(response.live_class_data ?? []);
        setShowLoading(false);
        FilteringDataAccordingToRaiseTicket(response.live_class_data);
        return response.live_class_data;
      } else {
        console.log("Error coming");
        setShowLoading(false);
        setLiveClassData([]);
      }
    } catch (e) {
      setShowLoading(false);
      console.log(e);
      setLiveClassData([]);
    }
  }
  //Filtering Raise Ticket
  const filteringMethod=(datas)=>{
    let enabledTicketDatas=[]
    
    let tempData=datas?.filter(data=>{
     if(data.ticket_raised &&data?.raised_ticket_status!="closed")
     {
       enabledTicketDatas.push({...data})
   }
   return !(data.ticket_raised &&data?.raised_ticket_status!="closed")
    })
    tempData=[...enabledTicketDatas,...tempData]
    return [...tempData]
  }
  const FilteringDataAccordingToRaiseTicket = (datas) => {
 
    setMainData([...datas])
    let tempData=filteringMethod(datas)
    setLiveClassData(tempData);

    showResultAccordingToFilter(tempData, [], false);
  };
  const [currentPosts, setCurrentPosts] = useState([]);
  const [currentPosts2, setCurrentPosts2] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  //Filtering Raise Ticket
  const handlePaginationNextPrevPage = (data, currentPage) => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentPosts(currentPosts);
  };
  const showResultAccordingToFilter = (
    liveClassdata,
    liveClassid,
    checkOnChange,
    currentPage = 1
  ) => {
    
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPosts = checkOnChange
      ? liveClassid.slice(indexOfFirstPost, indexOfLastPost)
      : liveClassdata.slice(indexOfFirstPost, indexOfLastPost);
    const totalPosts = liveClassid?.length||liveClassdata?.length;
    setCurrentPosts([...currentPosts]);
    console.log('show filtering')
    setTotalPosts(totalPosts);
  };

  //pagination logic
  const pagenumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pagenumbers.push(i);
  }
  const paginate = (num) => {
    setCurrentPage(num);

    if (classStatus?.length > 0) {
      handlePaginationNextPrevPage(currentPosts2, num);
      return;
    }
    showResultAccordingToFilter(liveClassdata, liveClassid, checkOnChange, num);
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    if (classStatus?.length > 0) {
      handlePaginationNextPrevPage(currentPosts2, currentPage - 1);
      return;
    }

    showResultAccordingToFilter(liveClassdata, liveClassid, checkOnChange, currentPage - 1);
  };
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    if (classStatus?.length > 0) {
      handlePaginationNextPrevPage(currentPosts2, currentPage + 1);
      return;
    }

    showResultAccordingToFilter(liveClassdata, liveClassid, checkOnChange, currentPage + 1);
  };
  let pageIncrementBtn = null;
  if (pagenumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <div onClick={handleNextbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <div onClick={handlePrevbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }

  // const showStudentEmotions = (live_class_id, live_user_id) => {
  //   axios
  //     .get(
  //       `https://begalileo.com/live_class_admins/live_class_emotions?user_id=18588&live_class_id=${live_class_id}&live_user_id=${live_user_id}`
  //     )
  //     .then((res) => {
  //
  //       if (res.data.message == "No emotions are captured under this class") {
  //         //setShowNoData(true);
  //         alert("No emotions found for this class");
  //       } else {
  //         showmodal(true);
  //         setmodalData(res.data);
  //
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const showStudentEmotions = (live_class_id, live_user_id) => {
    showmodal(true);
    setmodalData([live_class_id, live_user_id]);
  };

  const showTeacherRecording = (recording_link) => {
    // showRecordModal(true);
    setRecordingData(recording_link);
  };

  const showErrorLogsDetails = (live_class_id) => {
    showErrorDetailsModal(true);
    setErrorDetailsData(live_class_id);

    fetch(
      `${baseUrl}live_class_admins/live_class_room_sids_and_error_logs?user_id=18588&live_class_id=${live_class_id}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          let errorLogs = [];
          let message = "";
          let sortdata = data.error_logs.sort((a, b) =>
            a.error_message > b.error_message ? 1 : b.error_message > a.error_message ? -1 : 0
          );

          errorLogs = sortdata.filter((item) => {
            if (message == "" || item.error_message != message) {
              message = item.error_message;
              return 1;
            } else {
              return 0;
            }
          });
          setErrorDetailsFullData(errorLogs);

          setRoomSidData(data.room_sids);
        } else {
          console.log("Error");
        }
      });
  };

  const showRoomSidLogsDetails = (live_class_id, e) => {
    showTeacherRecording(e);

    showRoomSidDetailsModal(true);
    showErrorLogsDetails(live_class_id);
    setsidshowdata(live_class_id);
    // fetch(
    //   `https://www.begalileo.com/live_class_admins/live_class_room_sids_and_error_logs?user_id=18588&live_class_id=${live_class_id}`
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //

    //     if (data.status) {
    //       if (data.room_sids.length > 0) {
    //         setRoomSidDataforDetails(data.room_sids[0].room_sid);
    //       } else {
    //         setRoomSidDataforDetails("");
    //       }
    //     } else {
    //       console.log("Error");
    //     }
    //   });
  };

  const child = () => {
    showmodal(false);
  };

  const childRecord = async () => {
    showRecordModal(false);
    showErrorDetailsModal(false);
    showRoomSidDetailsModal(false);

    let result = await getListingData();
    setMainData([...result])
    result=filteringMethod(result)
    handleFilteringData(result);

    setLiveClassData(result);
  };
  const [inputChange, setInputChange] = useState("");
  const filterHightolow = (value) => {
   setSelectedClient(value)
    setInputChange('')
    let x = [];
    if(value=="timelow"){
      console.log('dd')
    x=[...mainData]}
    else{
      console.log('dkdkdkkdk')
    for (let i = mainData.length - 1; i >= 0; i--) {
      x.push(mainData[i]);
    }}
    showResultAccordingToFilter(x,[],false,1)
    setOnChange(false)
    setCurrentPage(1)
    setClassStatus([])
    setLiveClassData(x);
  };

  const handleChange = (e) => {
    if (showloading) return;
    let arr = ["teacher", "student", "id", "general","Demo"];
    if (e.target.value == "time" || e.target.value == "timelow") {
      filterHightolow(e.target.value);
    }
    setSelectedClient(e.target.value);
    if(selectedClient==="Demo")
    {
      handleBothInput({ target: { value: inputChange?.trim() } }, e.target.value);
    }
    if (arr.includes(e.target.value)) {
      if (inputChange?.trim() == ""&&e.target.value!=="Demo") return;
      handleBothInput({ target: { value: inputChange?.trim() } }, e.target.value);
    }
  };
  // filtering data after updation
  const handleFilteringData = (data) => {
    let x = selectedClient;
    
    if (inputChange.trim() == ""&&x!="Demo") {
      
      setOnChange(false);
      showResultAccordingToFilter(data, [], false, currentPage);

      filteringDataClassStatusAfterSearchApply(data)
    } else if (x == "teacher") {
      let y = data
        ?.filter((item) => item[x].toLowerCase().includes(inputChange?.trim().toLowerCase()))
        .map((item) => {
          return item;
        });
      
      setOnChange(true);
      showResultAccordingToFilter(data, y, true, currentPage);
      filteringDataClassStatusAfterSearchApply(y)
      setLiveClassID(y);
    } else if (x == "student") {
      let value1 = inputChange?.trim();
      let y = data?.filter(function (element) {
        let g = [];
        g = element.students.filter(function (subElement) {
          return subElement.name.toLowerCase().includes(value1.toLowerCase());
        });
        if (g.length > 0) {
          return true;
        }
      });
      showResultAccordingToFilter(data, y, true, currentPage);
      setOnChange(true);
      filteringDataClassStatusAfterSearchApply(y);
      setLiveClassID(y);
    } else if (x == "id") {
      let value1 = inputChange?.trim();
      let y = data
        .filter((item) => String(item?.id).includes(String(value1.trim())))
        .map((item) => {
          return item;
        });
      setOnChange(true);
      setLiveClassID(y);
      showResultAccordingToFilter(data, y, true, currentPage);
      filteringDataClassStatusAfterSearchApply(y);
    } else if (x == "general") {
      let y = data?.filter((item) => {
        if (String(item?.teacher)?.toLowerCase()?.includes(inputChange?.toLowerCase()?.trim()))
          return true;
        else if (String(item?.id)?.includes(inputChange?.trim())) return true;
        else {
          let students = item?.students;
          for (let names of students) {
            if (String(names?.name)?.toLowerCase()?.includes(inputChange?.trim()?.toLowerCase()))
              return true;
          }
        }
        return false;
      });

      setOnChange(true);
      showResultAccordingToFilter(data, y, true, currentPage);
      filteringDataClassStatusAfterSearchApply(y);
      setLiveClassID([...y]);
    }
    else if (x == "Demo") {
      let y = data?.filter(item=>item?.type=="Demo")
      setOnChange(true);
      showResultAccordingToFilter(data, y, true, currentPage);
      filteringDataClassStatusAfterSearchApply(y)
      setLiveClassID(y);
    }
  };

  const handleBothInput = (e, selectedClient) => {
    if (showloading) return;
    setClassStatus([]);
    setCurrentPage(1);
    let x = selectedClient;
    let arr = ["teacher", "student", "id", "general","Demo"];
    
    if (!arr.includes(x)) return;
    
    if (e?.target?.value?.trim() == ""&&selectedClient!=="Demo") {
      setInputChange(e?.target?.value?.trim());
      setOnChange(false);
      setCurrentPage(1);
      showResultAccordingToFilter(liveClassdata, liveClassid, false);
    } else {
      setInputChange(e.target.value);
      if (x == "teacher") {
        let y = liveClassdata
          .filter((item) => item[x].toLowerCase().includes(e.target.value.toLowerCase()))
          .map((item) => {
            return item;
          });

        setOnChange(true);
        setCurrentPage(1);
        setLiveClassID(y);
        showResultAccordingToFilter(liveClassdata, y, true);
      } else if (x == "student") {
        let y = liveClassdata.filter(function (element) {
          let g = [];
          g = element.students.filter(function (subElement) {
            return subElement.name.toLowerCase().includes(e.target.value.toLowerCase());
          });
          if (g.length > 0) {
            return true;
          }
        });
        setCurrentPage(1);
        setOnChange(true);
        setLiveClassID(y);
        showResultAccordingToFilter(liveClassdata, y, true);
      } else if (x == "id") {
        let y = liveClassdata
          .filter((item) => String(item?.id).includes(String(e.target.value)?.trim()))
          .map((item) => {
            return item;
          });
        setCurrentPage(1);
        showResultAccordingToFilter(liveClassdata, y, true);
        setOnChange(true);
        setLiveClassID(y);
      } else if (x == "general") {
        
        let y = liveClassdata?.filter((item) => {
          if (
            String(item?.teacher)
              ?.toLowerCase()
              ?.includes(String(e?.target?.value)?.toLowerCase()?.trim())
          )
            return true;
          else if (String(item?.id)?.toLowerCase()?.includes(String(e?.target?.value)?.trim()))
            return true;
          else {
            let studentsData = item?.students;
            for (let student of studentsData) {
              if (
                String(student?.name)
                  ?.toLowerCase()
                  ?.includes(String(e?.target?.value)?.trim()?.toLowerCase())
              )
                return true;
              else return false;
            }
          }
        });
         setOnChange(true);
        setCurrentPage(1);
        setLiveClassID(y);
        showResultAccordingToFilter(liveClassdata, y, true);
      }
      else if (x == "Demo") {
        setInputChange('')
        let y = liveClassdata
          .filter((item) => item?.type==="Demo")
          .map((item) => {
            return item;
          });
        setCurrentPage(1);
        showResultAccordingToFilter(liveClassdata, y, true);
        setOnChange(true);
        setLiveClassID(y);
      }
      
      
    }
  };
  const handleEnableLiveClass = async (liveClassId) => {
    if (enableDisableStatus) {
      return;
    }
    setEnableDisableStatus(true);
    try {
      let userId = localStorage.getItem("user");
      let result = await enableLiveClass(userId, liveClassId);
      result = await getListingData();
      setEnableDisableStatus(false);
      alert("Live class has been enabled.");
      if (result?.length > 0) {
        setMainData([...result])
        result=filteringMethod(result)

        setLiveClassData(result);
        handleFilteringData(result);
      }
    } catch (e) {
      setEnableDisableStatus(false);
    }
  };
  const handleDisableLiveClass = async (liveClassId) => {
    if (enableDisableStatus) {
      return;
    }
    setEnableDisableStatus(true);

    try {
      let userId = localStorage.getItem("user");

      await disableLiveClass(userId, liveClassId);

      let result = await getListingData();
      if (result?.length > 0) {
        setMainData([...result])
        result=filteringMethod(result)
        setLiveClassData(result);
        handleFilteringData(result);
      }

      setEnableDisableStatus(false);
    } catch (e) {
      setEnableDisableStatus(false);
    }
  };
  const handleJoinClass = async (liveClassId) => {
    try {
      const userId = localStorage.getItem("user");

      JoinLiveClass(userId, liveClassId)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          let { url } = res;

          window.open(url, "_blank");
        });
    } catch (e) {
      console.log("error handling", e);
    }
  };

  const enableDisableHandler = (status, liveClassId) => {
    if (enableDisableStatus) return;
    setEnableDisableStatus(true);
    if (status) {
      handleDisableLiveClass(liveClassId);
    } else {
      handleEnableLiveClass(liveClassId);
    }
  };
  const [dateNow, setDateNow] = useState("");
  useEffect(() => {
    let dateTable = [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let date = todayDate.split("/");

    let temp = date[0];

    date[0] = Number(date[2]);
    date[2] = temp;
    let mon = Number(date[1]) || 1;
    date[1] = dateTable[mon - 1];
    date = date.join(" ");

    setDateNow(date);
  }, [todayDate]);

  const dateRef = useRef();
  const [totalRegularClasses, setTotalRegularClasses] = useState(0);
  const [totalDemoClasses, setTotalDemoClasses] = useState(0);
  const [totalRegularClassesCompleted, setTotalRegularClassesCompleted] = useState(0);
  const [totalDemoClassesCompleted, setTotalDemoClassesCompleted] = useState(0);
  const [totalCompletedClasses, settotalCompletedClasses] = useState(0);
  const regularDemoClassCount = (data) => {
    let demoCount = 0;
    let regularCount = 0;
    let completedDemoClassCount=0
    let completedRegularClassCount=0
    let completedClassCount=0
    for (let item of data){
      if(item?.status==="Completed")
      completedClassCount++
      if (String(item?.type)?.trim() === "Demo") {
        demoCount++
        if(item?.status==="Completed")
        completedDemoClassCount++
      }
      else if (String(item?.type)?.trim() === "Regular") {
        regularCount++
        if(item?.status==="Completed")
        completedRegularClassCount++
      }
    }
    settotalCompletedClasses(completedClassCount)
    setTotalDemoClasses(demoCount);
    setTotalRegularClasses(regularCount);
    setTotalDemoClassesCompleted(completedDemoClassCount)
    setTotalRegularClassesCompleted(completedRegularClassCount)
  };

  const searchRef = useRef(null);
  const filteringDataClassStatus = (arr) => {
    let currentPosts2 = checkOnChange ? liveClassid : liveClassdata;
    if (arr.length < 1) {
      setCurrentPosts2([...currentPosts2]);
      setCurrentPage(1);
      setTotalPosts(currentPosts2.length)
     
      handlePaginationNextPrevPage(currentPosts2, 1);
      return;
    }

    let currentPosts = currentPosts2.filter((item) => arr.includes(item?.status));
    setCurrentPosts2([...currentPosts]);
    setCurrentPage(1);
    setTotalPosts(currentPosts.length)
    handlePaginationNextPrevPage(currentPosts, 1);
  };

  const filteringDataClassStatusAfterSearchApply = (data) => {
    let arr = classStatus;
    if (arr.length < 1) {
      setCurrentPosts2([...data]);
      handlePaginationNextPrevPage(data, currentPage);
      return;
    }

    let currentPosts = data?.filter((item) => arr.includes(item?.status));
    setCurrentPosts2([...currentPosts]);
    handlePaginationNextPrevPage(currentPosts, currentPage);
  };
  const handleClassStatus = (arr) => {
    filteringDataClassStatus(arr);
    setClassStatus([...arr]);
  };
  const stringMatching = (str1, str2) => {
    
    let index = str1?.toLowerCase().indexOf(str2?.toLowerCase());

    if (index < 0) return str1;
    let tempStr = str1.slice(index, index+str2?.length);

    tempStr = str1?.replace(
      tempStr?.trim(),
      `<span style="color:yellowgreen">${tempStr?.trim()}</span>`
    );
   
    return `<span>${tempStr}</span>`;
  };

  return (
    <>
      <DashboardLayout>
        {modalRecord && (
          <ModalforRecord childforRecord={childRecord} recordingData={recordingdata} />
        )}
        {modal && <Modal child={child} modalData={modaldata} />}
        <div style={{ display: "flex", gap: "2rem" }}>
          <div>
            <DatePicker
              id="DataPicker"
              className={styles.DatePicker}
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              onChange={(date) => {
                if (showloading) return;
                setStartDate(date);
              }}
              ref={dateRef}
            />
          </div>

          <div style={{ display: "flex", gap: "2rem" }}>
            <div>
              <input
                className={styles.DatePickersecond}
                placeholder="Enter Name"
                value={inputChange}
                onInput={(e) => {
                  handleBothInput(e, selectedClient);
                }}
                ref={searchRef}
              />
            </div>
            <div className="select-container">
              <select
                value={selectedClient}
                onChange={handleChange}
                className={styles.DatePickersecond}
              >
                <option value="teacher">Teacher </option>
                <option value="student">Student</option>
                <option value="id">Live Class ID</option>
                <option value="general">General</option>
                <option value="time">Time High To Low</option>
                <option value="timelow">Time Low To High</option>
                <option value="Demo">Demo</option>
              </select>
            </div>
          </div>
          {/* <div>
            <button
              className={styles.sortbyTime}
              onClick={() => {
                filterHightolow();
              }}
            >
              Sort by Time
            </button>
          </div> */}
        </div>
        {!showloading && (
          <div className={styles.classCountContainer}>
            <div className={styles.classCountColor}>Class Count = {totalCompletedClasses}/{liveClassdata?.length}</div>
            <div>Demo Classes = {totalDemoClassesCompleted}/{totalDemoClasses}</div>
            <div>Regular Classes = {totalRegularClassesCompleted}/{totalRegularClasses}</div>
           
          </div>
        )}
        {roomsiddetailsmodal ? (
          <ViewMoreForSid
            childforRecord={childRecord}
            liveclassid={errorshowsid}
            roomSiddata={roomSiddata}
            roomSiddata2={roomsiddata}
            errordetailsdata={errordetailsdata}
            liveClassdata={liveClassdata}
            errorlogsfulldata={data}
            errorshowdata={errorshownotdata}
            roomshowdata={errorshownotdataroomsid}
            x={errorMessage}
            y={errorlogsdata}
            recordingData={recordingdata}
          />
        ) : (
          <>
            <SuiBox py={3}>
              <SuiBox mb={3}>
                <Card>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SuiTypography variant="h6">Classes Day wise Details</SuiTypography>
                  </SuiBox>

                  <SuiBox customClass={classes.tables_table}>
                    {!showloading && currentPosts ? (
                      <table
                        style={{ borderCollapse: "collapse", width: "100%", tableLayout: "fixed" }}
                        className={styles.listTable}
                      >
                        <tr className={styles.rowBorder}>
                          <th className={styles.reducePadding}>No</th>
                          <th>Live ID</th>
                          <th>Teacher</th>
                          <th>Student</th>

                          <th>Manager</th>
                          <th>Country</th>
                          <th>
                            <DaywiseStatusField
                              classStatus={classStatus}
                              onClick={handleClassStatus}
                            />
                          </th>
                          <th>Enable/Disable Class</th>
                          <th>Join Class</th>
                          <th>Date & Time</th>

                          <th></th>
                        </tr>
                        {currentPosts && currentPosts?.length > 0 ? (
                          currentPosts.map((e, i) => {
                            return (
                              <>
                                <tr
                                  /*</>className={`${!(e.teacher_ticket||e.students.student_ticket)&&styles.orangeWhite}`}*/ className={`${
                                    e.ticket_raised &&e?.raised_ticket_status!="closed"&& styles.orangeWhite
                                  }`}
                                >
                                  <td style={{
                                    background:`${e.type=="Demo"?"lightgreen":"initial"}`
                                  }}>{(currentPage - 1) * postsPerPage + (i + 1)}</td>
                                  <td>
                                    {(() => {
                                      if (
                                        (selectedClient === "id" || selectedClient === "general") &&
                                        inputChange?.trim()
                                      ) {
                                        let id = String(e?.id)?.replace(
                                          inputChange,
                                          `<span style="color:yellowgreen">${inputChange?.trim()}</span>`
                                        );

                                        return HTMLReactParser(id);
                                      }
                                      return e.id;
                                    })()}
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    key={i}
                                    onClick={() => {
                                      showStudentEmotions(e.id, e.teacher_id);
                                    }}
                                  >
                                    <div
                                      style={{
                                        overflowWrap: "anywhere",
                                        display: "flex",
                                      }}
                                    >
                                      {(() => {
                                        if (
                                          (selectedClient === "teacher" ||
                                            selectedClient === "general") &&
                                          inputChange?.trim() &&
                                          e.teacher
                                            ?.toLowerCase()
                                            ?.includes(inputChange?.trim()?.toLowerCase())
                                        ) {
                                          return HTMLReactParser(
                                            stringMatching(e.teacher, inputChange?.trim())
                                          );
                                        }
                                        return e.teacher;
                                      })()}
                                      {e.teacher_ticket && (
                                        <sup className={styles.blinkImage}></sup>
                                      )}
                                    </div>
                                  </td>
                                  <td
                                  // style={{
                                  //   display: "flex",
                                  //   flexDirection: "column",
                                  //   verticalAlign: "baseline",
                                  // }}
                                  >
                                    <div>
                                      <ul className={styles.bulletContainer}>
                                        {e.students.map((es, i) => {
                                          return (
                                            <div
                                              style={{ display: "flex", cursor: "pointer" }}
                                              onClick={() => {
                                                showStudentEmotions(e.id, es.id);
                                              }}
                                            >
                                              <li style={{ cursor: "pointer" }} key={i}></li>
                                              <div
                                                style={{
                                                  overflowWrap: "anywhere",
                                                  display: "flex",
                                                }}
                                              >
                                                {(() => {
                                                  if (
                                                    (selectedClient === "student" ||
                                                      selectedClient === "general") &&
                                                    inputChange?.trim() &&
                                                    es.name?.toLowerCase()
                                                      ?.includes(inputChange?.trim()?.toLowerCase())
                                                  ) {
                                                    return HTMLReactParser(
                                                      stringMatching(es.name, inputChange?.trim())
                                                    );
                                                  }
                                                  return es.name;
                                                })()}
                                                {es?.student_ticket && (
                                                  <sup className={styles.blinkImage}></sup>
                                                )}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </td>

                                  <td>
                                    <div>
                                      <ul className={styles.bulletContainer}>
                                        {(() => {
                                          let arr = e.students?.map((es) => es.academic_manager);
                                          let obj = {};
                                          for (let item of arr) {
                                            if (obj[item.trim()] === undefined) obj[item] = 1;
                                          }
                                          arr = [];
                                          for (let key in obj) {
                                            arr.push(key);
                                          }
                                          let arr2 = arr.map((item) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  cursor: "pointer",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {arr.length > 1 && item && (
                                                  <li style={{ cursor: "pointer" }} key={i}></li>
                                                )}
                                                <div
                                                  style={{
                                                    overflowWrap: "anywhere",
                                                    display: "flex",
                                                  }}
                                                >
                                                  {item}
                                                </div>
                                              </div>
                                            );
                                          });
                                          return arr2;
                                        })()}
                                      </ul>
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <div>
                                      <ul className={styles.bulletContainer}>
                                        {e.students.map((es, i) => {
                                          return (
                                            <div style={{ display: "flex", cursor: "pointer" }}>
                                              <li style={{ cursor: "pointer" }} key={i}></li>
                                              <div
                                                style={{
                                                  overflowWrap: "anywhere",
                                                  display: "flex",
                                                }}
                                              >
                                                {es.country || "Null"}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </td>
                                  <td>{e.status}</td>
                                  <td>
                                    <div
                                      className={styles.btnContainer}
                                      onClick={() =>
                                        enableDisableHandler(e.live_class_join_status, e.id)
                                      }
                                    >
                                      <div className={styles.btn1_container}>
                                        <span
                                          className={`${styles.one} ${
                                            e.live_class_join_status && styles.inactive1
                                          }`}
                                        ></span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {(() => {
                                      const isDisabled =
                                        !e.live_class_join_status ||
                                        disabledJoined ||
                                        e.status === "Cancelled";
                                      return (
                                        <Button
                                          className={styles.joinBtn}
                                          disabled={isDisabled}
                                          onClick={() => handleJoinClass(e.id)}
                                          variant="contained"
                                          style={{
                                            color: "white",
                                            background: `${isDisabled ? "grey" : "limegreen"}`,
                                          }}
                                        >
                                          Join
                                        </Button>
                                      );
                                    })()}
                                  </td>
                                  <td>
                                    <div>{e.day}</div>
                                    <div>{e.time}</div>
                                  </td>

                                  <td
                                    style={{ cursor: "pointer" }}
                                    key={i}
                                    onClick={() => {
                                      showRoomSidLogsDetails(e.id, e);
                                    }}
                                  >
                                    <span
                                      onClick={() => {
                                        showRoomSidLogsDetails(e.id, e);
                                      }}
                                    >
                                      {e.status !== "Cancelled" && (
                                        <Button
                                          variant="contained"
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          View
                                        </Button>
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={11} rowspan={2}>
                              <p style={{ color: "#233584" }}>
                                No classes available with the selected date ...
                              </p>
                            </td>
                          </tr>
                        )}
                      </table>
                    ) : showloading ? (
                      ""
                    ) : (
                      <div className="loadingdiv_container">
                        <div>
                          <div>
                            <div className="loading">
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div md={12} className="text-center">
                            <p style={{ color: "#233584" }}>
                              No classes available with the selected date ...
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </SuiBox>
                </Card>
              </SuiBox>
            </SuiBox>
            {showData ? (
              <div className="showDataDiv">
                <h1>No Emotions Found in the class</h1>
              </div>
            ) : null}

            {showloading ? (
              <div className="loadingdiv_container">
                <div>
                  <div>
                    <div className="loading">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>

                <div>
                  <div md={12} className="text-center">
                    <p style={{ color: "#233584" }}>Fetching Data Please wait ...</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.button}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  circular="true"
                  iconOnly="true"
                  onClick={handlePrevbtn}
                  disabled={currentPage == pagenumbers[0] ? true : false}
                >
                  -
                </SuiButton>
                {pageDecrementBtn}
                {pagenumbers.map((number, i) => {
                  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
                    return (
                      <SuiButton
                        key={number}
                        onClick={() => {
                          paginate(number);
                        }}
                        variant="gradient"
                        color="info"
                        size="small"
                        circular="true"
                        iconOnly="true"
                        style={
                          i == currentPage - 1 ? { background: "yellow" } : { background: "white" }
                        }
                      >
                        {number}
                      </SuiButton>
                    );
                  } else {
                    return null;
                  }
                })}
                {pageIncrementBtn}
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  circular="true"
                  iconOnly="true"
                  onClick={handleNextbtn}
                  disabled={currentPage == pagenumbers[pagenumbers.length - 1] ? true : false}
                >
                  +
                </SuiButton>
              </div>
            )}
            <div ref={vref}></div>
          </>
        )}
      </DashboardLayout>
    </>
  );
}

export default daywise;
