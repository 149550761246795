import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import styles from "../custom.module.css";
import { useEffect, useState, useRef } from "react";
import SuiButton from "components/SuiButton";
import DatePicker from "react-datepicker";
import SuiBox from "components/SuiBox";
import styless from "layouts/tables/styles";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import ViewMoreSidErrorLog from "./ViewMoreSidFromErrorLog/ViewMoreSidErrorLog";
import { baseUrl } from "api";

function Pagination({ insidepage }) {
  const classes = styless();
  const [startDate, setStartDate] = useState(new Date());

  const vref = useRef(null);
  const [datalogs, setDataLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [smonth, setMonth] = useState("");
  const [syear, setYear] = useState(2020);
  const [loading, setLoading] = useState("");
  const [liveClassid, setLiveClassID] = useState([]);
  const [checkOnChange, setOnChange] = useState(false);
  useEffect(() => {
    let x = document.getElementById("DataPicker").value;
    let [month, year] = x.split("/").map((e) => (e * 1).toString());
    setMonth(month);
    setYear(year);
    setLoading(true);
  }, [startDate]);
  useEffect(() => {
    vref.current.scrollIntoView({ behavior: "smooth" });
  }, [currentPage]);
  useEffect(() => {
    fetch(
      `${baseUrl}live_class_admins/live_class_error_logs?user_id=18588&month=${smonth}&year=${syear}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          setLoading(false);
  
          setDataLogs(data.error_log_data);
          setLiveClassID(data.error_log_data);
        } else {
          return;
        }
      });
  }, [smonth]);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = checkOnChange
    ? liveClassid.slice(indexOfFirstPost, indexOfLastPost)
    : datalogs.slice(indexOfFirstPost, indexOfLastPost);
  const totalPosts = datalogs.length;
  //pagination logic
  const pagenumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pagenumbers.push(i);
  }
  const paginate = (num) => {
    setCurrentPage(num);
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  let pageIncrementBtn = null;
  if (pagenumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <div onClick={handleNextbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <div onClick={handlePrevbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }
  const handleToggle = () => {
    insidepage();
  };

  const filterHightolow = () => {
    let temp = checkOnChange ? liveClassid : datalogs;
    let x = [];
    for (let i = temp.length - 1; i >= 0; i--) {
      x.push(temp[i]);
    }

    checkOnChange ? setLiveClassID(x) : setDataLogs(x);
  };

  const changeLiveClassID = (e) => {
    if (e.keyCode == 13 && e.target.value == "") {
      setOnChange(false);
      fetch(
        `${baseUrl}live_class_admins/live_class_error_logs?user_id=18588&month=${smonth}&year=${syear}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setLoading(false);
            setDataLogs(data.error_log_data);
            setLiveClassID(data.error_log_data);
          } else {
            return;
          }
        });
    } else if (e.keyCode === 13) {
      let y = datalogs
        .filter((item) => item.live_class_id == e.target.value)
        .map((item) => {
          return item;
        });
  
      setOnChange(true);
      setLiveClassID(y);
    }
  };
  const [currentLiveClassId,setCurrentLiveClassId]=useState("")
  const [message,setMessage]=useState("")
  const handleShowError=(liveClassId,message)=>{
setCurrentLiveClassId(liveClassId)
setMessage(message)
  }
  const handleCloseChildModal=()=>{
    setCurrentLiveClassId("")
    setMessage("")
  }
  return (
    <>
    {currentLiveClassId&&<ViewMoreSidErrorLog liveClassId={currentLiveClassId} errorData={datalogs} message={message} onClick={handleCloseChildModal}/>}
     <div style={{display:`${currentLiveClassId?'none':'initial'}`}}>
     <DashboardLayout >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DatePicker
            id="DataPicker"
            className={styles.DatePicker}
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
          <div className={styles.inputboxStyle}>
            <input
              placeholder="Enter Live Class Id"
              onKeyDown={(e) => {
                changeLiveClassID(e);
              }}
              // onChange={(e) => {
              //   handleChange(e);
              // }}
            ></input>
          </div>
          <div className={styles.filterbutton}>
            <SuiButton
              variant="gradient"
              color="success"
              onClick={() => {
                filterHightolow();
              }}
            >
              Sort By Date
            </SuiButton>
          </div>
          <div className={styles.filterbutton}>
            <SuiButton
              variant="gradient"
              color="success"
              onClick={() => {
                handleToggle();
              }}
            >
              Show Group of errors
            </SuiButton>
          </div>
        </div>

        {/* <div className={styles.inp}>
          {showcalendar ? (
            <MonthYearPicker
              selectedMonth={smonth}
              selectedYear={syear}
              minYear={2000}
              maxYear={2030}
              onChangeYear={(year) => setYear(year)}
              onChangeMonth={(month) => setMonth(month)}
            />
          ) : null}
        </div> */}
        {loading ? (
          <h1>Fetching Data.....</h1>
        ) : (
          <div className={styles.outer}>
            <div className={styles.inner_o}>
              <div className={styles.mapped}>
                <SuiBox py={3}>
                  <SuiBox mb={3}>
                    <SuiBox customClass={classes.tables_table}>
                      <table
                        style={{ borderCollapse: "collapse", width: "100%", tableLayout: "fixed" }}
                      >
                        <tr >
                          <th>Live Class Id</th>
                          <th>Error Code</th>
                          <th>Message</th>
                          <th>Date</th>
                        </tr>
                        {currentPosts &&
                          currentPosts.map((e, i) => {
                            return (
                              <>
                                <tr onClick={()=>handleShowError(e.live_class_id,e.message)} style={{cursor:'pointer'}}>
                                  <td>{e.live_class_id}</td>
                                  <td>{e.code}</td>
                                  <td>{e.message}</td>
                                  <td>{e.created_at}</td>
                                </tr>
                              </>
                            );
                          })}
                      </table>
                    </SuiBox>
                  </SuiBox>
                </SuiBox>

                {/* <Table
                  columns={[
                    { name: "Live_Class_ID", align: "center " },
                    { name: "Error_Id", align: "left" },
                    { name: "Message", align: "left" },
                    { name: "Date", align: "center" },
                  ]}
                  rows={currentPosts.map((e, i) => {
                    return {
                      Live_Class_ID: e.live_class_id,
                      Error_Id: e.error_log_id,
                      Message: e.message,
                      Date: e.created_at,
                    };
                  })}
                /> */}
              </div>
              {/* <div className={styles.heading}>
                <div>Live Class ID</div>
                <div>Error Log ID</div>
                <div className={styles.message_h}>Message</div>
                <div>Created At</div>
              </div>
              <div>
                {currentPosts.map((i) => (
                  <div key={i.error_log_id} className={styles.content}>
                    <div>{i.live_class_id}</div>
                    <div>{i.error_log_id}</div>
                    <div className={styles.message}>{i.message}</div>
                    <div>{i.created_at}</div>
                  </div>
                ))}
              </div> */}
              <div className={styles.button}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  circular="true"
                  iconOnly="true"
                  onClick={handlePrevbtn}
                  disabled={currentPage == pagenumbers[0] ? true : false}
                >
                  -
                </SuiButton>
                {pageDecrementBtn}
                {pagenumbers.map((number, i) => {
                  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
                    return (
                      <SuiButton
                        key={number}
                        onClick={() => {
                          paginate(number);
                        }}
                        variant="gradient"
                        color="info"
                        size="small"
                        circular="true"
                        iconOnly="true"
                        style={
                          i == currentPage - 1 ? { background: "yellow" } : { background: "white" }
                        }
                      >
                        {number}
                      </SuiButton>
                    );
                  } else {
                    return null;
                  }
                })}
                {pageIncrementBtn}
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  circular="true"
                  iconOnly="true"
                  onClick={handleNextbtn}
                  disabled={currentPage == pagenumbers[pagenumbers.length - 1] ? true : false}
                >
                  +
                </SuiButton>
              </div>
            </div>
          </div>
        )}

        <div ref={vref}></div>
      </DashboardLayout>
     </div>
    </>
  );
}

export default Pagination;
