import React from "react";
import styles from "../custom2.module.css";
export default function ImageReview({ onClick, src }) {
  return (
    <div className={styles.modalImageView}>
      <div style={{ width: "100%", height: "auto" }} onClick={onClick}>
        <div className={styles.closeBtn}>X</div>
      </div>
      {src && (
        <div>
          <img src={src} width="100%" />
        </div>
      )}
    </div>
  );
}
