import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styless from "layouts/tables/styles";
import styles from "./daywiseRaiseTicket.module.css";
import SuiButton from "components/SuiButton";
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import { baseUrl } from "api";
import ViewMoreDetailsRaiseTicket from "./ViewMoreDetailsRaiseTicket";
import { closedRaiseTicket } from "api";
let userID = 18588;
function DaywiseRaiseTicket() {
  const classes = styless();
  const vref = useRef(null);
  const [liveClassdata, setLiveClassData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [showloading, setShowLoading] = useState(true);
  const [showData, setShowNoData] = useState(false);
  const [modal, showmodal] = useState(false);
  const [errordetailsmodal, showErrorDetailsModal] = useState(false);
  const [roomsiddetailsmodal, showRoomSidDetailsModal] = useState(false);
  const [roomsiddata, setRoomSidData] = useState([]);
  const [modaldata, setmodalData] = useState([]);
  const [recordingdata, setRecordingData] = useState("");
  const [errordetailsdata, setErrorDetailsData] = useState("");
  const [selectedClient, setSelectedClient] = useState("id"); //default value
  const [liveClassid, setLiveClassID] = useState([]);
  const [checkOnChange, setOnChange] = useState(false);
  const [data, setErrorDetailsFullData] = useState([]);
  const [currentDatasView, setCurrentDatasView] = useState();
  const [showViewMoreModal, setShowViewMoreModal] = useState(false);
  const [classStatus, setClassStatus] = useState([]);
  const [roomSiddata, setRoomSidDataforDetails] = useState([]);
  const [todayDate, setTodayDate] = useState("");
  useEffect(() => {
    vref.current.scrollIntoView({ behavior: "smooth" });
  }, [currentPage]);

  useEffect(async () => {
    setClassStatus([]);
    setShowLoading(true);
    setSelectedClient("id");
    setInputChange("");
    setCurrentPage(1);

    await getListingData();
    setOnChange(false);

    // axios
    //   .get(
    //     `https://begalileo.com/live_class_admins/daywise_classes?user_id=18588&start_date=${currentDate}`
    //   )
    //   .then((res) => {
    //
    //     setLiveClassData(res.data.live_class_data);
    //     setShowLoading(false);
    //   })
    //   .catch((error) => {
    //
    //   });
  }, []);

  async function getListingData() {
    try {
      let response;

      await fetch(`${baseUrl}live_class_admins/get_all_raised_ticket?user_id=18588`)
        .then((response) => response.json())
        .then((data) => {
          setLiveClassData(data?.data);
          // regularDemoClassCount(response.live_class_data ?? []);

          FilteringDataAccordingToRaiseTicket(data?.data);
          setShowLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setShowLoading(false);
        });

      // let response = await AdminApi.dayWiseList({
      //   user_id: userID,
      //   start_date: currentDate,
      // });
    } catch (e) {
      setShowLoading(false);
      console.log(e);
      setLiveClassData([]);
    }
  }
  //Filtering Raise Ticket
  const FilteringDataAccordingToRaiseTicket = (datas) => {
    if(inputChange?.trim())
    {
      handleBothInput({target:{value:inputChange?.trim()}},"id",datas)
      return

    }

    showResultAccordingToFilter(datas, [], false);
  };
  const [currentPosts, setCurrentPosts] = useState([]);
  const [currentPosts2, setCurrentPosts2] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  //Filtering Raise Ticket
  const handlePaginationNextPrevPage = (data, currentPage) => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentPosts(currentPosts);
  };
  const showResultAccordingToFilter = (
    liveClassdata,
    liveClassid,
    checkOnChange,
    currentPage = 1
  ) => {
    console.log("show Result");
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPosts = checkOnChange
      ? liveClassid.slice(indexOfFirstPost, indexOfLastPost)
      : liveClassdata.slice(indexOfFirstPost, indexOfLastPost);
    const totalPosts = liveClassdata.length;
    setCurrentPosts([...currentPosts]);
    setTotalPosts(totalPosts);
  };

  //pagination logic
  const pagenumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pagenumbers.push(i);
  }
  const paginate = (num) => {
    setCurrentPage(num);

    if (classStatus?.length > 0) {
      handlePaginationNextPrevPage(currentPosts2, num);
      return;
    }
    showResultAccordingToFilter(liveClassdata, liveClassid, checkOnChange, num);
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    if (classStatus?.length > 0) {
      handlePaginationNextPrevPage(currentPosts2, currentPage - 1);
      return;
    }

    showResultAccordingToFilter(liveClassdata, liveClassid, checkOnChange, currentPage - 1);
  };
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    if (classStatus?.length > 0) {
      handlePaginationNextPrevPage(currentPosts2, currentPage + 1);
      return;
    }

    showResultAccordingToFilter(liveClassdata, liveClassid, checkOnChange, currentPage + 1);
  };
  let pageIncrementBtn = null;
  if (pagenumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <div onClick={handleNextbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <div onClick={handlePrevbtn} style={{ cursor: "pointer" }}>
        {" "}
        &hellip;{" "}
      </div>
    );
  }


const handleCloseTicket=async (i)=>{
  try{
    let userId=localStorage.getItem('user')
    let errorId=currentPosts[i]?.id
    console.log(errorId)
    await closedRaiseTicket(userId,errorId)
    await getListingData()
  }
  catch(e){

  }
}
 

  const handleViewMoreRaiseTicket = (i) => {
    setCurrentDatasView(currentPosts[i]);
    setShowViewMoreModal(true);

    // fetch(
    //   `https://www.begalileo.com/live_class_admins/live_class_room_sids_and_error_logs?user_id=18588&live_class_id=${live_class_id}`
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //

    //     if (data.status) {
    //       if (data.room_sids.length > 0) {
    //         setRoomSidDataforDetails(data.room_sids[0].room_sid);
    //       } else {
    //         setRoomSidDataforDetails("");
    //       }
    //     } else {
    //       console.log("Error");
    //     }
    //   });
  };

  const [inputChange, setInputChange] = useState("");
  const filterHightolow = () => {
    let x = [];
    for (let i = liveClassdata.length - 1; i >= 0; i--) {
      x.push(liveClassdata[i]);
    }
    setLiveClassData(x);
  };

  const handleChange = (e) => {
    if (showloading) return;
    let arr = ["teacher", "student", "id", "general"];
    if (e.target.value == "time" || e.target.value == "timelow") {
      filterHightolow();
    }
    setSelectedClient(e.target.value);
    if (arr.includes(e.target.value)) {
      if (inputChange?.trim() == "") return;
      handleBothInput({ target: { value: inputChange?.trim() } }, e.target.value);
    }
  };
  // filtering data after updation
 
  const handleBothInput = (e, selectedClient,liveClassdata) => {
    if (showloading) return;
    setClassStatus([]);
    setCurrentPage(1);
    let x = selectedClient;
    let arr = ["teacher", "student", "id", "general"];
    if (!arr.includes(x)) return;

    if (e?.target?.value?.trim() == "") {
      setInputChange(e?.target?.value?.trim());
      setOnChange(false);
      setCurrentPage(1);
      showResultAccordingToFilter(liveClassdata, liveClassid, false);
    } else {
      setInputChange(e.target.value);
      if (x == "teacher") {
        let y = liveClassdata
          .filter((item) => item[x].toLowerCase().includes(e.target.value.toLowerCase()))
          .map((item) => {
            return item;
          });

        setOnChange(true);
        setCurrentPage(1);
        setLiveClassID(y);
        showResultAccordingToFilter(liveClassdata, y, true);
      } else if (x == "student") {
        let y = liveClassdata.filter(function (element) {
          let g = [];
          g = element.students.filter(function (subElement) {
            return subElement.name.toLowerCase().includes(e.target.value.toLowerCase());
          });
          if (g.length > 0) {
            return true;
          }
        });
        setCurrentPage(1);
        setOnChange(true);
        setLiveClassID(y);
        showResultAccordingToFilter(liveClassdata, y, true);
      } else if (x == "id") {
        let y = liveClassdata
          .filter((item) => String(item?.live_class_id).includes(String(e.target.value)?.trim()))
          .map((item) => {
            return item;
          });
        setCurrentPage(1);
        showResultAccordingToFilter(liveClassdata, y, true);
        setOnChange(true);
        setLiveClassID(y);
      } else if (x == "general") {
        console.log("general");
        let y = liveClassdata?.filter((item) => {
          if (
            String(item?.teacher)
              ?.toLowerCase()
              ?.includes(String(e?.target?.value)?.toLowerCase()?.trim())
          )
            return true;
          else if (String(item?.id)?.toLowerCase()?.includes(String(e?.target?.value)?.trim()))
            return true;
          else {
            let studentsData = item?.students;
            for (let student of studentsData) {
              if (
                String(student?.name)
                  ?.toLowerCase()
                  ?.includes(String(e?.target?.value)?.trim()?.toLowerCase())
              )
                return true;
              else return false;
            }
          }
        });
        setOnChange(true);
        showResultAccordingToFilter(data, y, true, 1);
        setCurrentPage(1);
        setLiveClassID([...y]);
      }
    }
  };

  const [dateNow, setDateNow] = useState("");
  useEffect(() => {
    let dateTable = [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    let date = todayDate.split("/");

    let temp = date[0];

    date[0] = Number(date[2]);
    date[2] = temp;
    let mon = Number(date[1]) || 1;
    date[1] = dateTable[mon - 1];
    date = date.join(" ");

    setDateNow(date);
  }, [todayDate]);

  const searchRef = useRef(null);
  const filteringDataClassStatus = (arr) => {
    let currentPosts2 = checkOnChange ? liveClassid : liveClassdata;
    if (arr.length < 1) {
      setCurrentPosts2([...currentPosts2]);
      setCurrentPage(1);
      handlePaginationNextPrevPage(currentPosts2, 1);
      return;
    }

    let currentPosts = currentPosts2.filter((item) => arr.includes(item?.status));
    setCurrentPosts2([...currentPosts]);
    setCurrentPage(1);
    handlePaginationNextPrevPage(currentPosts, 1);
  };

  const filteringDataClassStatusAfterSearchApply = (data) => {
    let arr = classStatus;
    if (arr.length < 1) {
      setCurrentPosts2([...data]);
      handlePaginationNextPrevPage(data, currentPage);
      return;
    }

    let currentPosts = data?.filter((item) => arr.includes(item?.status));
    setCurrentPosts2([...currentPosts]);
    handlePaginationNextPrevPage(currentPosts, currentPage);
  };
const handleCloseModal=()=>{
  setShowViewMoreModal(false)
}
  return (
    <>
      <DashboardLayout>
        <div style={{ display: "flex", gap: "2rem" }}>
          <div></div>

          <div style={{ display: "flex", gap: "2rem" }}>
            <div>
              <input
                className={styles.DatePickersecond}
                placeholder="Enter id"
                value={inputChange}
                onInput={(e) => {
                  handleBothInput(e, selectedClient,liveClassdata);
                }}
                ref={searchRef}
              />
            </div>
            <div className="select-container">
              <select
                value={selectedClient}
                onChange={handleChange}
                className={styles.DatePickersecond}
              >
                <option value="id">Live Class ID</option>
              </select>
            </div>
          </div>
          {/* <div>
            <button
              className={styles.sortbyTime}
              onClick={() => {
                filterHightolow();
              }}
            >
              Sort by Time
            </button>
          </div> */}
        </div>
        {!showloading && (
          <div className={styles.classCountContainer}>
            <div className={styles.classCountColor}>
              Total Raised Ticket = {liveClassdata?.length}
            </div>
          </div>
        )}
        {showViewMoreModal ? (
          <ViewMoreDetailsRaiseTicket onClick={handleCloseModal} viewDatas={currentDatasView}/>
        ) : (
          <>
            <SuiBox py={3}>
              <SuiBox mb={3}>
                <Card>
                  <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SuiTypography variant="h6">Classes Day wise Details</SuiTypography>
                  </SuiBox>

                  <SuiBox customClass={classes.tables_table}>
                    {!showloading && currentPosts ? (
                      <table
                        style={{ borderCollapse: "collapse", width: "100%", tableLayout: "fixed" }}
                        className={styles.listTable}
                      >
                        <tr className={styles.rowBorder}>
                          <th className={styles.reducePadding}>No</th>

                          <th>Error Id</th>
                          <th>Live ID</th>
                          <th>Error Type</th>
                          {/* <th>Description</th>

                          <th>Os</th>
                          <th>Device</th>
                          <th>
                          Browser
                          </th>
                          
                          <th>ScreenShots</th> */}
                          <th>Created Date</th>

                          <th>Updated Date</th>
                        </tr>
                        {currentPosts && currentPosts?.length > 0 ? (
                          currentPosts?.map((e, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>{(currentPage - 1) * postsPerPage + (i + 1)}</td>

                                  <td>{e?.id}</td>

                                  <td>{e?.live_class_id}</td>

                                  <td>
                                    <td>{e?.error_type}</td>
                                  </td>

                                  {/* <td>
                                  {e?.error_description}
                                  </td>
                                  <td>
                                  {e?.operating_system}
                                    
                                  </td>
                                  <td>{e?.device}</td>
                                  <td>{e?.browser}</td>
                                  <td>
                                  {"images"}
                                  </td> */}
                                  <td>{e?.created_at}</td>
                                  <td>{e?.updated_at}</td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    key={i}
                                    onClick={() => {
                                      handleViewMoreRaiseTicket(i);
                                    }}
                                  >
                                    <span>
                                      <Button
                                        variant="contained"
                                        style={{
                                          color: "white",
                                        }}
                                      >
                                        View
                                      </Button>
                                    </span>
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    key={i}
                                    onClick={() => {
                                      handleCloseTicket(i);
                                    }}
                                  >
                                    <span>
                                      <Button
                                        variant="contained"
                                        style={{
                                          color: "white",backgroundColor:'red'
                                        }}
                                      >
                                        Close 
                                      </Button>
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={6} rowspan={2}>
                              <p style={{ color: "#233584" }}>
                                No raise ticket available ...
                              </p>
                            </td>
                          </tr>
                        )}
                      </table>
                    ) : showloading ? (
                      ""
                    ) : (
                      <div className="loadingdiv_container">
                        <div>
                          <div>
                            <div className="loading">
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div md={12} className="text-center">
                            <p style={{ color: "#233584" }}>
                              No classes available with the selected date ...
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </SuiBox>
                </Card>
              </SuiBox>
            </SuiBox>
            {showData ? (
              <div className="showDataDiv">
                <h1>No Emotions Found in the class</h1>
              </div>
            ) : null}

            {showloading ? (
              <div className="loadingdiv_container">
                <div>
                  <div>
                    <div className="loading">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>

                <div>
                  <div md={12} className="text-center">
                    <p style={{ color: "#233584" }}>Fetching Data Please wait ...</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.button}>
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  circular="true"
                  iconOnly="true"
                  onClick={handlePrevbtn}
                  disabled={currentPage == pagenumbers[0] ? true : false}
                >
                  -
                </SuiButton>
                {pageDecrementBtn}
                {pagenumbers.map((number, i) => {
                  if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
                    return (
                      <SuiButton
                        key={number}
                        onClick={() => {
                          paginate(number);
                        }}
                        variant="gradient"
                        color="info"
                        size="small"
                        circular="true"
                        iconOnly="true"
                        style={
                          i == currentPage - 1 ? { background: "yellow" } : { background: "white" }
                        }
                      >
                        {number}
                      </SuiButton>
                    );
                  } else {
                    return null;
                  }
                })}
                {pageIncrementBtn}
                <SuiButton
                  variant="gradient"
                  color="info"
                  size="small"
                  circular="true"
                  iconOnly="true"
                  onClick={handleNextbtn}
                  disabled={currentPage == pagenumbers[pagenumbers.length - 1] ? true : false}
                >
                  +
                </SuiButton>
              </div>
            )}
            <div ref={vref}></div>
          </>
        )}
      </DashboardLayout>
    </>
  );
}

export default DaywiseRaiseTicket;
