import styles from "./custom2.module.css";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";

function RecordingView({ childforRecord, recordingData }) {
  console.log({ recordingData });
  const player = useRef(null);
  const [playbackRate, setPlayBackRate] = useState(1);

  const closeModal = () => {
    childforRecord();
  };

  function forwardVideo(e) {
    console.log("Forward Video");
    console.log(e.target);
    console.log(e.target.value);
    let currentDuration = player.current.getCurrentTime();
    currentDuration += (e.target.value || 1) * 60;
    player.current.seekTo(parseFloat(currentDuration));
  }
  function backwardVideo(e) {
    console.log("Forward Video");
    let currentDuration = player.current.getCurrentTime();
    currentDuration -= e.target.value * 60;
    player.current.seekTo(parseFloat(currentDuration));
  }

  function handleSetPlaybackRate(e) {
    setPlayBackRate(parseFloat(e.target.value));
  }

  useEffect(() => {
    console.log("Record View");

    // var vid = document.getElementById("myVideoPlayer");
    // vid.addEventListener("loadedmetadata", function () {
    //   if (vid.duration == Infinity) {
    //     vid.currentTime = 24 * 60 * 60;
    //     vid.ontimeupdate = function () {
    //       this.ontimeupdate = () => {
    //         return;
    //       };
    //       vid.currentTime = 0;
    //       return;
    //     };
    //   }
    // });
    // console.log("Video Duration", vid.duration);
  });
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <div className={styles.titleCloseBtn}>
          <button
            onClick={() => {
              closeModal();
            }}
          >
            X
          </button>
        </div>

        {recordingData ? (
          <div className={`${styles.modal_mapped_recording} ${styles.videoContainer}`}>
            {console.log(recordingData)}
            {/* <video
              id="myVideoPlayer"
              src={recordingData}
              width={700}
              height={500}
              controls
              preload="metadata"

            ></video> */}
            <ReactPlayer
              style={{ width: "600px", height: "500px" }}
              ref={player}
              playing={true}
              controls={true}
              playbackRate={playbackRate}
              url={`${recordingData}`}
            />

            <div className={styles.controlButtonContainer}>
              <button onClick={() => backwardVideo({ target: { value: 1 } })} value={1}>
                <FastRewindIcon value={1} fontSize="medium" />
              </button>
              <button onClick={forwardVideo} style={{ width: "auto" }} value={1}>
                <FastForwardIcon value={1} fontSize="medium" />
              </button>
            </div>
            <div className={styles.controlButtonContainer}>
              <button
                onClick={handleSetPlaybackRate}
                value={1}
                style={{
                  background: `${playbackRate == 1 ? "blue" : "initial"}`,
                  color: `${playbackRate == 1 ? "white" : "initial"}`,
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                1x
              </button>
              <button
                onClick={handleSetPlaybackRate}
                value={1.5}
                style={{
                  background: `${playbackRate == 1.5 ? "blue" : "initial"}`,
                  color: `${playbackRate == 1.5 ? "white" : "initial"}`,
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                1.5x
              </button>
              <button
                onClick={handleSetPlaybackRate}
                value={2}
                style={{
                  background: `${playbackRate == 2 ? "blue" : "initial"}`,
                  color: `${playbackRate == 2 ? "white" : "initial"}`,
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                2x
              </button>
              <button
                onClick={handleSetPlaybackRate}
                value={4}
                style={{
                  background: `${playbackRate == 4 ? "blue" : "initial"}`,
                  color: `${playbackRate == 4 ? "white" : "initial"}`,
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                4x
              </button>
              <button
                onClick={handleSetPlaybackRate}
                value={16}
                style={{
                  background: `${playbackRate == 16 ? "blue" : "initial"}`,
                  color: `${playbackRate == 16 ? "white" : "initial"}`,
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                16x
              </button>
            </div>
          </div>
        ) : (
          <div>No Recording Present</div>
        )}
      </div>
    </div>
  );
}

export default RecordingView;
