/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import styles from "./custom1.module.css";
import { encode } from "base-64";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ErrorLogView from "./ErrorLogView";
import RecordingView from "./RecordingView";
import RaiseTicketForm from "./RaiseTicketForm/RaiseTicketForm";
import { sendMediaRegionDetails } from "api";
import SuccessfulMessageModal from "./RaiseTicketForm/SuccessfulMessageModal";
import { closedTechSupport } from "api";
import ModalMessage from "./RaiseTicketForm/ModalMessage";
import { Button, duration } from "@mui/material";
import { baseUrl } from "api";
import SuiBox from "components/SuiBox";
import styless from "layouts/tables/styles";
import { getVideoMetadata } from "@remotion/media-utils";
export default function ViewMoreForSid({
  liveclassid,
  errorlogsfulldata,
  errordetailsdata,
  liveClassdata,
  x,
  roomSiddata2,
  y,
  errorshowdata,
  roomshowdata,
  childforRecord,
  recordingData,
  errorLogsViewSid,
  participantDetails
      
}) {
 const [recordingList,setRecordingList]=useState([])
 const [durationLength,setDurationLength]=useState([])
  const [show, setShow] = useState(true);
  const [totalRoomSid,setTotalRoomSid]=useState([])
  const [message, setMessage] = useState("");
  const [data, setRoomSidState] = useState([]);
  const [status, setStatus] = useState("");
  const [techSupportError, setTechSupportError] = useState(false);
  const [techSupportStatus, setTechSupportStatus] = useState(false);
  const classes = styless();
  const handleCloseTechSupport = async () => {
    let userId = localStorage.getItem("user");
    let { id } = recordingData;
    try {
      let result = await closedTechSupport(userId, id);
      let y = await result.json();

      setMessage(y?.message);
      setTechSupportStatus(true);
      setTechSupportError(false)
    } catch (e) {
      setMessage("Ticket Has been successully closed");
      setTechSupportStatus(false);
      setTechSupportError(true)
    }
  };
  const handleStatusChange = (e) => {
    if(status === "raise ticket")
    return
    if (e.target.value === "closed") {
      let y = confirm("Do you really want to close this ticket");
      if (y) {
        handleCloseTechSupport();
      }
    }
    setStatus(e.target.value);
  };
  const [showErrorLogsPage,setShowErrorLogsPage]=useState(false)
  const [roomSid,setRoomSid]=useState("")
  const [raiseTicketStatus, setRaiseTicketStatus] = useState(false);
  const findAllDuration=async (data)=>{
    let durationArray=[]
for(let item of data){
  try{
    let duration=await getVideoMetadata(item)
    let timeInSecond=Math.floor(duration?.durationInSeconds)
    durationArray.push(timeInSecond===Infinity?"NA":`${Math.floor(timeInSecond/3600).toString().padStart(2,0)}:${Math.floor((timeInSecond%3600)/60).toString().padStart(2,0)}:${Math.floor((timeInSecond%3600)%60).toString().padStart(2,0)}`)
  }
  catch(e){
    durationArray.push("NA")
  }
  
}
setDurationLength([...durationArray])
  }
  useEffect(() => {
    fetch(
      `${baseUrl}live_class_admins/live_class_room_sids_and_error_logs?user_id=18588&live_class_id=${liveclassid}`
    )
      .then((res) => res.json())
      .then(async (data) => {
        checkTeacherStudentTicket(data);
        setShowErrorLogsPage(data?.error_logs?.length>0?true:false)
        findAllDuration(data?.recordings||[])
        setRecordingList(data?.recordings||[])
        if (data.room_sids[0]?.room_sid) {
        
          setTotalRoomSid(data?.room_sids||[])
          setRoomSid(data.room_sids[0]?.room_sid)
        } else {
          setIsLoading(false);
          setRoomSid("")
        }
      });
  }, []);
  useEffect(() => {
   
    if(totalRoomSid?.length>0)
    getRoomSidFullDetails(totalRoomSid[0].room_sid,0);
    
  }, [totalRoomSid?.length||0]);
  const checkTeacherStudentTicket = (datas) => {
  
  //  console.log(datas?.ticket_raised_status)
    setStatus(String(datas?.ticket_raised_status).trim())
    console.log(datas?.ticket_raised)
    if(datas?.ticket_raised===true)
    {
      setRaiseTicketStatus(true)
      return
    }
    else{
      setRaiseTicketStatus(false)
    }

    
   
  };
  
  const [isLoading, setIsLoading] = useState(true);
  const getRoomSidFullDetails = async (x,index) => {
   console.log(x,index,totalRoomSid?.length)
    const ACCOUNT_SID = "AC6274fbee2b2fef9d9f7240377e613c3e";
    const AUTH_TOKEN = "5cf3fcbfbf076d3ec6f82ab5107df758";
    const URL = `https://insights.twilio.com/v1/Video/Rooms/${x}`;
    const response = await fetch(`https://insights.twilio.com/v1/Video/Rooms/${x}`, {
      method: "get",
      headers: new Headers({
        Authorization: "Basic " + encode(ACCOUNT_SID + ":" + AUTH_TOKEN),
        "Content-Type": "application/json",
      }),
    });
    try {
      const posts = await response.json();
   
      let concurrentParicipant=posts?.concurrent_participants||0
     
      if(concurrentParicipant){
        console.log(concurrentParicipant)
      data[data?.length||0]={...posts}
    }
      index++
      console.log(data)
      setRoomSidState([...data]);
      setRoomSid(x)
      setIsLoading(false);
      if(index<totalRoomSid?.length){
       
        if(liveclassid!=4&&liveclassid!=80032&&liveclassid!=491)
        await getRoomSidFullDetails(totalRoomSid[index].room_sid,index)
       }
      
    } catch (e) {
      setIsLoading(true);
    }
  };

  const closeModal = () => {
    childforRecord();
  };
  const handleMediaRegionChange = (e) => {
   
    setMediaValue(e.target.value);
  };
  const sendMediaRegion = async (liveclassid,value) => {
    const userId=localStorage.getItem('user')
    try {
      const result = await sendMediaRegionDetails(liveclassid,userId,value);
    } catch (e) {
      console.log(e);
    }
  };
  const [state, setState] = useState(false);
  const handleSubmit = () => {
    if(status === "raise ticket")
    return
    let y=confirm("Do you really want to change media region, if any participant joined it will be logged out?.")
    if(!y)
    return
    setState(true);
    sendMediaRegion(liveclassid,mediaValue);
  };
  let [currentRecording, setCurrentRecording] = useState("");
  const [tabSelected, setTabSelected] = useState(1);
  const [mediaValue, setMediaValue] = useState("gll");
  const [currentRecordingIndex, setCurrentRecordingIndex] = useState("");
  const handleTabChange = (e = "", value, index = "") => {
    if (status === 4) return;

    setCurrentRecording(e);
    setTabSelected(value);
    setCurrentRecordingIndex(index);
  };
  const handleCloseErrorModal = () => {
    if(status === "raise ticket")
    return
    setTabSelected(1);
    setCurrentRecording("");
    setCurrentRecordingIndex("");
  };
  const handleCloseForm = () => {
    setStatus(-1);
  };
  const closeModalMessage = () => {
    setTechSupportStatus(false);
  };

  const locationRef = useRef([
    { gll: "Global Low Latency (see section below)" },
    { au1: "Australia" },
    { br1: "Brazil" },
    { de1: "Germany" },
    { ie1: "Ireland" },
    { in1: "India" },
    { jp1: "Japan" },
    { sg1: "Singapore" },
    { us1: "US East Coast (Virginia)" },
    { us2: "US West Coast (Oregon)" },
  ]);
  
  return isLoading ? (
    <div className={styles.modalBackground}>
    <div className={styles.modalContainer}>
     
      <h1>Loading...</h1>
    </div>
  </div>
  ) : (
    <div>
      <>
        <div>
          {tabSelected === 3 && (
            <RecordingView
              childforRecord={handleCloseErrorModal}
              recordingData={currentRecording}
            />
          )}
          {status === "raise ticket" && <RaiseTicketForm onClick={handleCloseForm} liveclassid={liveclassid} />}
          {tabSelected === 2 && (
            <ErrorLogView
              childforRecord={handleCloseErrorModal}
              errordetailsdata={errordetailsdata}
              liveClassdata={liveClassdata}
              errorlogsfulldata={errorlogsfulldata}
              errorshowdata={errorshowdata}
              roomshowdata={roomshowdata}
              x={x}
              y={y}
              roomSiddata={roomSiddata2}
              participantDetails={participantDetails}
              liveClassId={liveclassid}
            />
          )}

          <div className={styles.modalBackground}>
            {state ? (
              <ModalMessage
                onClick={closeModal}
                message={"Media Region has been SuccessFully Sent"}
              />
            ) : (
              <div className={styles.modalContainer}>
                {techSupportError && (
                  <div className={styles.secondmainContainer}>
                    <div className={styles.wrongMessage}>
                      Something went wrong please try again...
                    </div>
                  </div>
                )}
                <div className={styles.titleCloseBtn}>
                  <button
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    X
                  </button>
                </div>
                    
                <div className={styles.secondmainContainer}>
                
                  {techSupportStatus && (
                    <ModalMessage onClick={closeModalMessage} message={message} />
                  )}
                   <SuiBox customClass={classes.tables_table}>
                    {!isLoading ? (
                      <table
                        style={{ borderCollapse: "collapse", width: "100%", tableLayout: "fixed" }}
                        className={styles.listTable}
                      >
                        <tr className={styles.rowBorder}>
                          <th className={styles.reducePadding}>No</th>
                          <th>Room Sid</th>
                          <th>Concurrent Participant</th>
                          <th>Edge Location</th>
                          <th>End Reason</th>

                          <th>End Time</th>
                          <th>Room Status</th>
                          <th>Room Type</th>
                         
                        </tr>
                        { data?.length>0?(
                          data.map((data, i) => {
                            return (
                              <>
                               <tr>
                               <td className={styles.reducePadding}>{i}</td>
                               <td>{data?.room_sid??roomSid??"Not Present"}</td>
                               <td>{data.concurrent_participants ?? "Not Present"}</td>
                               <td>{data?.edge_location ?? "Not Present"}</td>
                               <td>{data?.end_reason??"Not Present"}</td>
                               <td>{data.end_time ?? "Not Present"}</td>
                               <td>{data?.room_status ?? "Not Present"}</td>
                               <td>{data?.room_type ?? "Not Present"}</td>
                               </tr>
                              </>
                            );
                          })
                        ):  (
                          <>
                           <tr>
                           <td className={styles.reducePadding}>{0}</td>
                           <td>{data?.room_sid??roomSid??"Not Present"}</td>
                           <td>{data.concurrent_participants ?? "Not Present"}</td>
                           <td>{data?.edge_location ?? "Not Present"}</td>
                           <td>{data?.end_reason??"Not Present"}</td>
                           <td>{data.end_time ?? "Not Present"}</td>
                           <td>{data?.room_status ?? "Not Present"}</td>
                           <td>{data?.room_type ?? "Not Present"}</td>
                           </tr>
                          </>
                        ) }
                      </table>
                    ) : "" }
                  </SuiBox>
                  <div className={styles.secondmainContainerflexbox} style={{marginTop:"1rem"}}>
                    <h3>Media Region</h3>
                    <div className={styles.mediaRegionContainer}>
                    <div style={{ minWidth: "50px", width: "auto" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={mediaValue}
                        onChange={handleMediaRegionChange}
                        autoWidth
                        style={{ color: "black", fontSize: "20px" }}
                      >
                        {locationRef.current.map((item, i) => {
                          for (let key in item) {
                            return (
                              <MenuItem
                                value={key}
                                style={{ fontSize: `${i === 0 ? 20 : "initial"}` }}
                              >
                                {item[key]} - {key}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                     
                      </div>
                      <div>
                      <button className={styles.submitBtn} onClick={handleSubmit}>
                      Change Region
                      </button>
                    </div>
                    </div>
                  </div>
                 
                  <div  className={styles.secondmainContainerflexbox}>
                    <h3 >View Error Log</h3>
                    <div>
                      {showErrorLogsPage?<Button
                        variant="contained"
                        color="error"
                        background="primary"
                        style={{ backgroundColor: "red", color: "white",cursor:'pointer' }}
                        onClick={() => handleTabChange("", 2, "")}
                      >
                        View
                      </Button>:<h3>No errors reported</h3>}
                    </div>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Recordings</h3>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      {recordingList?.length > 0 ? (
                        recordingList?.map((e, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              handleTabChange(e, 3, i);
                            }}
                            className={styles.numberBtn2}
                            style={{
                              backgroundColor: `${
                                i === currentRecordingIndex ? "yellow" : "initial"
                              }`,
                            }}
                          >
                            {i + 1}
                          </div>
                        ))
                      ) : (
                        <b>Not Present</b>
                      )}
                    </div>
                  </div>
                  <div className={styles.secondmainContainerflexbox}>
                    <h3>Video Durations</h3>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      {durationLength?.length > 0 ? (
                        durationLength?.map((e, i) => (
                          <div
                            key={i}
                         
                           
                            style={{
                              backgroundColor: `${
                                i === currentRecordingIndex ? "yellow" : "initial"
                              }`,
                            }}
                          >
                          {e}
                          </div>
                        ))
                      ) : (
                        <b>Not Present</b>
                      )}
                    </div>
                  </div>
                  {raiseTicketStatus && (
                    <div className={styles.secondmainContainerflexbox}>
                      <h3>Ticket Status</h3>
                      <div style={{ minWidth: "50px", width: "auto" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={status}
                          onChange={handleStatusChange}
                          autoWidth
                          style={{ color: "black", fontSize: "20px" }}
                        >
                          <MenuItem value={-1} style={{ fontSize: 20 }}>
                            Select Status
                          </MenuItem>

                          <MenuItem value={"open"}>Open</MenuItem>
                          <MenuItem value={"closed"}>Closed</MenuItem>
                          <MenuItem value={"raise ticket"}>Raise Tickets</MenuItem>
                        </Select>
                      </div>
                    </div>
                  )}
                 
                </div>
                {/* </>
                );
              })} */}
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
