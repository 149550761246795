import axios from "./index";

class AuthApi {
  static Login = (params) => {
    return axios.post(`live_class_admins/login`, params, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
    });
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  static Logout = (data) => {
    return axios.post(`${base}/logout`, data, { headers: { Authorization: `${data.token}` } });
  };
}

let base = "users";

export default AuthApi;
