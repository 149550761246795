import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import SuiButton from "components/SuiButton";
import styles from "./simplemonitor.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SuiBox from "components/SuiBox";
import styless from "layouts/tables/styles";
import { encode } from "base-64";

function SimpleMonitor({ insidegroup }) {
  const classes = styless();
  const [startDate, setStartDate] = useState(new Date());
  const [charts, setCharts] = useState(false);
  const [loading, setLoading] = useState(false);


  const ACCOUNT_SID = "AC6274fbee2b2fef9d9f7240377e613c3e";
  const AUTH_TOKEN = "5cf3fcbfbf076d3ec6f82ab5107df758";
  const [details,setDetails]=useState([])
  const getRoomSidFullDetails = async (x) => {
      const ACCOUNT_SID = "AC6274fbee2b2fef9d9f7240377e613c3e";
      const AUTH_TOKEN = "5cf3fcbfbf076d3ec6f82ab5107df758";
      // const URL = `https://insights.twilio.com/v1/Video/Rooms/${x}`;
      const response = await fetch(`https://insights.twilio.com/v1/Video/Rooms/${x}`, {
        method: "get",
        headers: new Headers({
          Authorization: "Basic " + encode(ACCOUNT_SID + ":" + AUTH_TOKEN),
          "Content-Type": "application/json",
        }),
      });
      try {
        const posts = await response.json();
        let details=posts?.participants||[]
        console.log(details)
        details=details.sort((a,b)=>{

return a.participant_identity.trim()===b.participant_identity.trim()?0:a.participant_identity.trim()<b.participant_identity.trim()?1:-1

        })
      

          
         setDetails(details||[])
       
      } catch (e) {
     
      }
    };
    useEffect(async ()=>{
getRoomSidFullDetails('RM4a7af8b7d5d62d3339af8213f8cb38ab/Participants')
    },[])
    console.log(details)
  return (
    <>
      {charts ? (""
      ) : (
        <div>
          <div
            style={{
              zIndex: "1",
              padding: "10px",
            
            }}
          >
            <DashboardLayout>
              <div className={styles.sui_button}>
                {/* <div>
                  <DatePicker
                    id="DataPicker"
                    className={styles.DatePicker}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </div> */}
                {/* <div>
                  <input
                    className={styles.datePickerSecond}
                    placeholder="Enter Message"
                  
                  
                  ></input>
                </div> */}
              </div>

              {loading ? (
                <h1 style={{ marginTop: "50px" }}>Fetching the data.....</h1>
              ) : (
                <SuiBox customClass={classes.tables_table}>
                  <table className={styles.errorTable}>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Join Time</th>
                      <th>Leave Time</th>
                      <th>Duration</th>
                      <th>Status</th>
                     
                    </tr>
{details?.map((item,i)=><tr>
    <th>{i+1}</th>
    <th>{item?.participant_identity==="tutor"?item?.participant_identity:item?.participant_identity?.split('-')[1]}</th>
    <th>{new Date(item?.join_time).toTimeString()}</th>
    <th>{item?.leave_time}</th>
    
    <th>{item?.duration_sec}sec</th>
    <th>{item?.status}</th>
</tr>)}
                   
                  </table>
                </SuiBox>
              )}
            </DashboardLayout>
          </div>
        </div>
      )}
    </>
  );
}
export default SimpleMonitor;
