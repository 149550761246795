import axios from "./index";

class AdminApi {
  static IncompleteRecordingList = (data) => {
    return axios.post(`live_class_admins/view_recording_lists`, data);
  };
  static recordUpdatingToServer = (data) => {
    return axios.post(`live_class_admins/update_recording_status`, data);
  };

  static deactivateRecording = (data) => {
    return axios.post(`app_students/inactive_recordings`, data);
  };

  static dayWiseList = (data) => {
    return axios.post(`live_class_admins/daywise_classes`, data);
  };
}

export default AdminApi;
